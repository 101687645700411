import StatEffect from "./statEffect";
import AttributeEffect from "./attributeEffect";
import { globalSettings } from "../services/twwstats-services";
import { RoundFloat } from "../services/common";

export default function Phase(data, tww_version, cuc) {
  if (!data) {
    return null;
  }

  const directDamageMultiplier =
    cuc.kv.find((a) => a.key === `direct_damage_${globalSettings().unit_size}`)
      ?.value ?? 1;

  this.tww_version = tww_version;

  this.id = data.id;
  this.onscreen_name = data.onscreen_name;
  // if (data.onscreen_name && /\[\[img:(.*?)]]/.test(data.onscreen_name)) {
  if (data.onscreen_name && /\]\]([^[].+)\[\[/.test(data.onscreen_name)) {
    // this.icon = data.onscreen_name.match(/\[\[img:(.*?)]]/)[1].replace('_upgraded', '');
    this.name = data.onscreen_name.match(/\]\]([^[].+)\[\[/)[1];
  } else {
    // use generic icon
    // this.icon = 'ui/skins/default/icon_alert_message.webp';
    // this.icon = 'ui/battle%20ui/ability_icons/wh2_main_lore_passive_combined.webp';
    this.tww_version = process.env.REACT_APP_TWWSTATS_LATEST_VERSION;
    this.name = data.onscreen_name || data.id;
  }

  this.icon = `ui/battle%20ui/ability_icons/${this.id.replace(
    "_upgraded",
    ""
  )}.webp`;

  this.effect_type = data.effect_type;
  this.requested_stance = data.requested_stance;
  this.unbreakable = data.unbreakable;
  this.cant_move = data.cant_move;
  this.freeze_fatigue = data.freeze_fatigue;
  this.fatigue_change_ratio = RoundFloat(data.fatigue_change_ratio);
  // this.inspiration_aura_change_mod = data.inspiration_aura_change_mod;
  this.ability_recharge_change = RoundFloat(data.ability_recharge_change);
  this.duration = data.duration;
  this.hp_change_frequency = data.hp_change_frequency;
  this.ticks = Math.floor(this.duration / this.hp_change_frequency) + 1;
  this.heal_amount = data.heal_amount;
  this.damage_chance = data.damage_chance;
  this.damage_amount = data.damage_amount
    ? Math.max(1, Math.floor(data.damage_amount * directDamageMultiplier))
    : 0; // _kv_unit_ability_scaling_rules table
  this.max_damaged_entities = data.max_damaged_entities;
  this.resurrect = data.resurrect;
  this.mana_regen_mod = RoundFloat(data.mana_regen_mod);
  this.mana_max_depletion_mod = RoundFloat(data.mana_max_depletion_mod);
  this.imbue_magical = data.imbue_magical;
  this.imbue_ignition = data.imbue_ignition;
  this.phase_display = data.phase_display;

  this.statEffects = data.stat_effects?.map(
    (v, i, a) => new StatEffect(v, tww_version)
  );
  this.attributeEffects = data.attribute_effects?.map(
    (v, i, a) => new AttributeEffect(v, tww_version, cuc)
  );
  if (data.imbue_contact) {
    this.imbuedEffect = new Phase(data.imbue_contact, tww_version, cuc);
  }
}
