import gql from "graphql-tag";
import ContactPhaseFragment from "../graphql/contactphase-fragment";
import ProjectileFragment from "../graphql/projectile-fragment";
import BattleEntityFragment from "../graphql/battle-entity-fragment";
import EffectBonusFragment from "../graphql/effect-bonus-fragment";

export default gql`
  query UnitQuery($tww_version: String!, $key: String!) {
    tww(tww_version: $tww_version) {
      tww_version
      game_version {
        name
      }
      unit(id: $key) {
        unit
        tier
        unit_sets {
          special_category
        }
        num_men
        caste
        multiplayer_cost
        recruitment_cost
        weight
        upkeep_cost
        create_time
        mount_name
        barrier_health
        is_high_threat
        can_siege
        bullet_points {
          key
          state
          sort_order
          onscreen_name
          tooltip
        }
        battle_mounts {
          base_unit
          mounted_unit
          icon_name
          mount_name
          localised_description
        }
        custom_battle_permissions {
          general_portrait
          set_piece_character {
            ancillaries {
              ancillary_effects {
                effect {
                  abilities {
                    ...effect_bonus
                  }
                }
              }
            }
          }
        }
        ui_unit_group {
          key
          icon
          name
          tooltip
        }
        factions(include_non_mp: false) {
          key
          screen_name
          flags_url
          name_group
        }
        land_unit {
          key
          short_description_text
          mount {
            key
          }
          variant {
            variant
            unit
            name
            unit_card_url
          }
          ground_stat_effect_group {
            group_name
            onscreen_name
            stat_effects {
              ground_type
              affected_group
              affected_stat
              multiplier
            }
          }
          num_engines
          num_mounts
          onscreen_name
          accuracy
          category
          charge_bonus
          melee_attack
          melee_defence
          morale
          bonus_hit_points
          attribute_group
          reload
          secondary_ammo
          primary_ammo
          damage_mod_flame
          damage_mod_magic
          damage_mod_physical
          damage_mod_missile
          damage_mod_all
          can_skirmish
          abilities {
            icon_name
            key
            name
            tooltip
            unit_special_ability {
              passive
              auto_deactivate_flags {
                alt_description
              }
            }
          }
          attributes {
            key
            bullet_text
            imbued_effect_text
          }
          special_ability_groups {
            abilities {
              icon_name
              key
              name
              tooltip
              overpower_option {
                icon_name
                key
                name
                tooltip
              }
            }
          }
          armour {
            key
            armour_value
          }
          unit_class {
            key
            onscreen
          }
          officers {
            additionnal_personalities {
              key
              battle_entity_stats {
                primary_missile_weapon {
                  use_secondary_ammo_pool
                  default_projectile {
                    ...projectile
                  }
                }
              }
            }
          }
          primary_melee_weapon {
            key
            bonus_v_cavalry
            bonus_v_large
            bonus_v_infantry
            damage
            ap_damage
            weapon_length
            melee_weapon_type
            splash_attack_target_size
            splash_attack_max_attacks
            splash_attack_power_multiplier
            ignition_amount
            is_magical
            contact_phase {
              ...contact_phase
            }
            collision_attack_max_targets
            collision_attack_max_targets_cooldown
            melee_attack_interval
          }
          primary_missile_weapon {
            use_secondary_ammo_pool
            default_projectile {
              ...projectile
            }
          }
          shield {
            key
            parry_chance
            material
          }
          battle_entity {
            ...battle_entity
          }
          mount {
            key
            variant
            battle_entity {
              ...battle_entity
            }
          }
          engine {
            key
            engine_type
            missile_weapon {
              use_secondary_ammo_pool
              default_projectile {
                ...projectile
              }
            }
            battle_entity {
              ...battle_entity
            }
          }
          articulated_vehicle_entity {
            hit_points
          }
        }
      }
    }
  }
  ${ContactPhaseFragment}
  ${ProjectileFragment}
  ${BattleEntityFragment}
  ${EffectBonusFragment}
`;
