import React, { useState, useEffect } from "react";

const styles = {
  container: {
    position: "relative",
    width: "384px", // equivalent to w-96
    color: "#000",
  },
  selectedArea: {
    minHeight: "26px",
    padding: "4px",
    border: "1px solid #e2e8f0",
    borderRadius: "6px",
    backgroundColor: "white",
    cursor: "pointer",
    display: "flex",
    flexWrap: "wrap",
    gap: "4px",
  },
  placeholder: {
    color: "#6b7280",
  },
  tag: {
    backgroundColor: "#dbeafe",
    color: "#1e40af",
    padding: "2px 4px",
    borderRadius: "6px",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  removeButton: {
    marginLeft: "4px",
    color: "#2563eb",
    cursor: "pointer",
    border: "none",
    background: "none",
    padding: "0 4px",
    fontSize: "16px",
    ":hover": {
      color: "#1e40af",
    },
  },
  dropdown: {
    position: "absolute",
    marginTop: "2px",
    width: "100%",
    border: "1px solid #e2e8f0",
    borderRadius: "6px",
    backgroundColor: "white",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    zIndex: 10,
  },
  searchSection: {
    padding: "4px",
    borderBottom: "1px solid #e2e8f0",
  },
  searchInput: {
    width: "100%",
    padding: "4px",
    border: "1px solid #e2e8f0",
    borderRadius: "6px",
    marginBottom: "4px",
    boxSizing: "border-box",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "8px",
  },
  actionButton: {
    fontSize: "14px",
    color: "#2563eb",
    background: "none",
    border: "none",
    cursor: "pointer",
    padding: "2px",
    ":hover": {
      color: "#1e40af",
    },
  },
  optionsList: {
    maxHeight: "240px",
    overflowY: "auto",
  },
  option: {
    padding: "4px 8px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    ":hover": {
      backgroundColor: "#f3f4f6",
    },
  },
  checkbox: {
    height: "16px",
    width: "16px",
    accentColor: "#2563eb",
  },
};

export const FactionMultiselect = ({ onSelected }) => {
  const [selectedFactions, setSelectedFactions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    onSelected(selectedFactions);
  }, [selectedFactions]);

  const factionNameMapping = {
    names_vmp_vampire_counts: "Vampire Counts",
    names_lzd_lizardmen: "Lizardmen",
    names_def_dark_elves: "Dark Elves",
    names_tze_tzeentch: "Tzeentch",
    names_wef_wood_elves: "Wood Elves",
    names_cst_vampire_coast: "Vampire Coast",
    names_skv_skaven: "Skaven",
    names_dwf_dwarfs: "Dwarfs",
    names_chd_chaos_dwarfs: "Chaos Dwarfs",
    names_nor_norsca: "Norsca",
    names_ksl_kislev: "Kislev",
    names_brt_bretonnia: "Bretonnia",
    names_bst_beastmen: "Beastmen",
    names_nur_nurgle: "Nurgle",
    names_hef_high_elves: "High Elves",
    names_tmb_tomb_kings: "Tomb Kings",
    names_emp_empire: "The Empire",
    names_grn_greenskins: "Greenskins",
    names_ogr_ogre_kingdom: "Ogre Kingdoms",
    names_chs_chaos: "Warriors of Chaos",
    names_sla_slaanesh: "Slaanesh",
    names_cth_cathay: "Grand Cathay",
    names_kho_khorne: "Khorne",
  };

  const factions = Object.entries(factionNameMapping)
    .filter(([key, name]) =>
      name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => a[1].localeCompare(b[1]));

  const toggleFaction = (factionKey) => {
    setSelectedFactions((prev) =>
      prev.includes(factionKey)
        ? prev.filter((key) => key !== factionKey)
        : [...prev, factionKey]
    );
  };

  const clearSelections = () => {
    setSelectedFactions([]);
  };

  const selectAll = () => {
    setSelectedFactions(Object.keys(factionNameMapping));
  };

  return (
    <div style={styles.container}>
      <div style={styles.selectedArea} onClick={() => setIsOpen(!isOpen)}>
        {selectedFactions.length === 0 ? (
          <span style={styles.placeholder}>Select factions...</span>
        ) : (
          selectedFactions.map((key) => (
            <span key={key} style={styles.tag}>
              {factionNameMapping[key]}
              <button
                style={styles.removeButton}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFaction(key);
                }}
              >
                ×
              </button>
            </span>
          ))
        )}
      </div>

      {isOpen && (
        <div style={styles.dropdown}>
          <div style={styles.searchSection}>
            <input
              type="text"
              placeholder="Search factions..."
              style={styles.searchInput}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div style={styles.actionButtons}>
              <button onClick={selectAll} style={styles.actionButton}>
                Select All
              </button>
              <button onClick={clearSelections} style={styles.actionButton}>
                Clear All
              </button>
            </div>
          </div>

          <div style={styles.optionsList}>
            {factions.map(([key, name]) => (
              <div
                key={key}
                style={{
                  ...styles.option,
                  backgroundColor: selectedFactions.includes(key)
                    ? "#f3f4f6"
                    : "transparent",
                }}
                onClick={() => toggleFaction(key)}
              >
                <input
                  type="checkbox"
                  checked={selectedFactions.includes(key)}
                  onChange={() => {}}
                  style={styles.checkbox}
                />
                <span>{name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
