import React from "react";
import { Tooltip } from "react-tippy";
import { cloudinaryUrl } from "../services/common";
import AbilityTooltipContainer from "./AbilityTooltipContainer";
import { useApolloClient } from "react-apollo";
import styled from "styled-components";

const AbilityIcon = ({ ability, width, height, iconStyle, tww_version }) => {
  const client = useApolloClient();

  const { name, icon_name, key, overpower_option } = ability;
  const icon = `ui/battle%20ui/ability_icons/${icon_name}.webp`;
  let cloudinaryTransform = [];

  if (width) {
    cloudinaryTransform.push(`w_${width}`);
  }
  if (height) {
    cloudinaryTransform.push(`h_${height}`);
  }

  const Icon = () => (
    <img
      alt={name}
      src={`${cloudinaryUrl(cloudinaryTransform)}/api/${
        tww_version || process.env.REACT_APP_TWWSTATS_LATEST_VERSION
      }/${icon}`}
      style={{ ...iconStyle, width: `${width}px`, height: `${height}px` }}
    />
  );

  const overpower = overpower_option
    ? {
        k: overpower_option.key,
        v: tww_version,
      }
    : null;

  return (
    <Tooltip
      position="top"
      useContext={true}
      arrow="true"
      style={{ display: "inline-flex" }}
      // trigger="click"
      html={
        <StyledAbilityTooltipContainer
          right={{ k: key, v: tww_version }}
          overpower={overpower}
          singleColumn
          apolloClient={client}
        />
      }
    >
      <Icon />
    </Tooltip>
  );
};

export default AbilityIcon;

const StyledAbilityTooltipContainer = styled(AbilityTooltipContainer)`
  position: relative;
  margin: -6px -14px;
  box-shadow: none;
`;
