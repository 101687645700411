import { isInt } from "./common";
import _ from "lodash";

const booleanOperators = ["&&", "&", "||", "|"];

const comparisonOperators = [">", ">=", "<", "<=", "<=", "=", "!=", "=="];

const aggregateWithAndOperators = [">", ">=", "<", "<=", "<=", "!="];

function preProcessFieldName(name) {
  if (typeof name !== "string") {
    return name;
  }

  // Always use lowercase
  name = name.toLowerCase().trim();

  // Replace spaces with underscores
  name = name.replace(/[\s-'"]/g, "_");

  // Couple of common typos because of UK/US differences in spelling
  name = name.replace("defense", "defence");
  name = name.replace("armor", "armour");
  name = name.replace("dmg", "damage");
  name = name.replace("resistence", "resistance");
  name = name.replace("armour_piercing", "ap");
  name = name.replace("_vs_", "_v_");
  name = name.replace("explosive", "explosion");
  name = name.replace("magic", "magical");
  name = name.replace("magicalal", "magical"); // That's just silly :P
  name = name.replace("regiments_of_renown", "ror");

  return name;
}

function getFieldName(name, synonyms) {
  return synonyms[preProcessFieldName(name)];
}

export function extractSynonyms(colDefs, dict) {
  if (!colDefs) {
    return null;
  }
  if (dict === undefined) {
    dict = {
      addPair(key, val) {
        key = preProcessFieldName(key);
        if (this[key]) {
          throw new Error(
            `Trying to add ${key}:${val} but ${key}:${this[key]} already exists.`
          );
        }
        this[key] = val;
      },
    };
  }

  return colDefs.reduce((d, colDef) => {
    const colName = preProcessFieldName(
      colDef.headerName ||
        (colDef.headerComponentParams && colDef.headerComponentParams.title)
    );
    // TODO This is a hack for Secondary Missile Weapons because I was too lazy to refactor the coldefs to avoid conflicts
    if (colName && d[colName]) {
      return d;
    }
    if (colName) {
      d.addPair(colName, colDef.field);
    }

    if (colName !== preProcessFieldName(colDef.field)) {
      d.addPair(colDef.field, colDef.field);
    }

    colDef.synonyms &&
      colDef.synonyms.reduce((d2, s) => {
        d2.addPair(s, colDef.field);
        return d2;
      }, d);
    colDef.children && extractSynonyms(colDef.children, d);

    return d;
  }, dict);
}

function getNodeType(node) {
  if (booleanOperators.indexOf(node.token) !== -1) {
    return "boolean";
  } else if (comparisonOperators.indexOf(node.token) !== -1) {
    return "comparison";
  } else if (Array.isArray(node.token)) {
    return "literalArray";
  }
  return "literal";
}

function booleanOperation(op, left, right, rowNode, synonyms) {
  switch (op) {
    case "&&":
    case "&":
      return (
        processNode(left, rowNode, synonyms) &&
        processNode(right, rowNode, synonyms)
      );
    case "||":
    case "|":
      return (
        processNode(left, rowNode, synonyms) ||
        processNode(right, rowNode, synonyms)
      );
    default:
      return true;
  }
}

function compareOperation(op, left, right, rowNode, synonyms) {
  const field = getFieldName(left.token, synonyms);

  let val;
  if (rowNode.columnController) {
    // Technically compareOperations are always leaves so assume left and right nodes are literals
    const col = _.find(
      rowNode.columnController.columnsForQuickFilter,
      (c) => c.colDef.field === field.toLowerCase()
    );
    val = rowNode.gridApi.filterManager.getQuickFilterTextForColumn(
      col,
      rowNode
    );
  } else {
    val = rowNode[field];
  }
  val = val && val.toString().toLowerCase();
  val = val === "" ? null : val;

  if (Array.isArray(right.token)) {
    return right.token.reduce((result, t) => {
      if (aggregateWithAndOperators.indexOf(op) !== -1) {
        return (
          result && doCompare(field, op, val, t && t.toLowerCase(), rowNode)
        );
      } else {
        return (
          result || doCompare(field, op, val, t && t.toLowerCase(), rowNode)
        );
      }
    }, aggregateWithAndOperators.indexOf(op) !== -1);
  }

  let target = right.token && right.token.toLowerCase();
  return doCompare(field, op, val, target, rowNode);
}

function doCompare(field, op, val, target, rowNode) {
  target =
    target === "true"
      ? "1"
      : target === "false"
      ? "0"
      : target === "null" || target === ""
      ? null
      : target;

  if (isInt(val)) {
    val = parseInt(val * 100, 10) / 100;
    target = target === "modified" ? target : parseInt(target * 100, 10) / 100;
  }

  switch (op) {
    case ">":
      return val > target;
    case ">=":
      return val >= target;
    case "<":
      return val < target;
    case "<=":
      return val <= target;
    case "=":
      if (
        typeof target === "string" &&
        target === "modified" &&
        field !== "delta_status"
      ) {
        return rowNode.data[`delta_${field}`] !== undefined;
      }
      if (val === null && target === null) {
        return true;
      }
      if (isInt(val)) {
        return val === target;
      } else {
        return val && val.indexOf(target) !== -1;
      }
    case "==":
      if (
        typeof target === "string" &&
        target === "modified" &&
        field !== "delta_status"
      ) {
        return rowNode.data[`delta_${field}`] !== undefined;
      }
      if (val === null && target === null) {
        return true;
      }
      if (isInt(val)) {
        return val === target;
      } else {
        return val === target;
      }
    case "!=":
      if (isInt(val)) {
        return val !== target;
      } else if (
        (val === null && target !== null) ||
        (val !== null && target === null)
      ) {
        return true;
      } else {
        return val && val.indexOf(target && target) === -1;
      }
    default:
      return true;
  }
}

function textFilter(text, rowNode) {
  let pass = false;
  if (rowNode.columnController) {
    rowNode.columnController.getAllColumnsForQuickFilter().forEach((col) => {
      const val = rowNode.gridApi.filterManager.getQuickFilterTextForColumn(
        col,
        rowNode
      );
      pass |= val && val.toLowerCase().indexOf(text.toLowerCase()) !== -1;
    });
  } else {
    Object.keys(rowNode).forEach((key) => {
      pass |=
        rowNode[key] &&
        rowNode[key].toLowerCase &&
        rowNode[key].toLowerCase().indexOf(text.toLowerCase()) !== -1;
    });
  }

  return pass;
}

// apply the ast to the provided rowNode and return TRUE if it matches and FALSE if it does not
export default function processNode(node, rowNode, synonyms) {
  try {
    if (!node || node.error) {
      return false;
    } // This node previously threw an execption, don't run it again

    const nodeType = getNodeType(node);
    if (nodeType === "boolean") {
      return booleanOperation(
        node.token,
        node.leftChildNode,
        node.rightChildNode,
        rowNode,
        synonyms
      );
    } else if (nodeType === "comparison") {
      return compareOperation(
        node.token,
        node.leftChildNode,
        node.rightChildNode,
        rowNode,
        synonyms
      );
    } else if (nodeType === "literalArray") {
      return node.token.reduce(
        (result, t) => result || textFilter(t, rowNode),
        false
      );
    } else {
      return textFilter(node.token, rowNode);
    }
  } catch (error) {
    node.error = true;
    console.error(
      `Node '${node.token}' with left: '${
        node.leftChildNode && node.leftChildNode.token
      }' and right: '${
        node.rightChildNode && node.rightChildNode.token
      }' error: ${error}`
    );
  }
}
