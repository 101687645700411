import React from "react";

export const BonusVsInfantryTooltip = ({ unit }) => {
  const bonusMA = unit.primary_melee_weapon.bonus_v_infantry || 0;
  const bonusDmg = Math.round(
    (bonusMA * unit.primary_melee_weapon.base_damage) /
      (unit.primary_melee_weapon.base_damage +
        unit.primary_melee_weapon.ap_damage)
  );
  const bonusAP = Math.round(
    (bonusMA * unit.primary_melee_weapon.ap_damage) /
      (unit.primary_melee_weapon.base_damage +
        unit.primary_melee_weapon.ap_damage)
  );

  return (
    <>
      <p>
        Gets the following bonuses when fighting Infantry (aka "Small") units:
      </p>
      <p>
        +{bonusMA} Melee Attack ({unit.melee_attack + bonusMA} total)
      </p>
      <p>
        +{bonusDmg} Damage ({unit.primary_melee_weapon.base_damage + bonusDmg}{" "}
        total)
      </p>
      <p>
        +{bonusAP} AP Damage ({unit.primary_melee_weapon.ap_damage + bonusAP}{" "}
        total)
      </p>
    </>
  );
};
