import React, { Fragment } from "react";

export default (props) => (
  <Fragment>
    <p style={{ fontWeight: "bold" }}>{`Melee Attack [MA]: ${props.value}`}</p>
    <p>
      % to Hit (TWW3) = 35 + 'Attacker's Melee Attack' - 'Defenders's Melee
      Defence'
    </p>
    <p>MIN 8%, MAX 90% (TWW3)</p>
  </Fragment>
);
