import _ from "lodash";
import queryString from "query-string";
import React, { Component, Fragment } from "react";
import FontAwesome from "react-fontawesome";
import Modal from "react-modal";
import UnitCardMeta from "../models/unit-card-meta";
import { array_move } from "../services/common";
import SimpleDialog from "./simple-dialog";
import UnitCard from "./unit-card";
import UnitFinder from "./unit-finder";

const CompareModeEnum = Object.freeze({ None: "1", First: "2", Previous: "3" });

export default class extends Component {
  constructor(props) {
    super(props);

    let parsed = queryString.parse(this.props.location.search);

    let ucms = parsed.units || [];

    if (!Array.isArray(ucms)) {
      ucms = [ucms];
    }

    ucms = ucms.map((u) => queryString.parse(u));

    this.state = {
      showUnitFinder: ucms.length === 0, // Show unit finder immediately if no units cards are being rendered
      ucms: ucms,
      compareMode: parsed.compare || CompareModeEnum.First,
      initialVersion: null,
      initialFaction: null,
    };
    this.cardsContainer = React.createRef();
    this.cards = [];
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let parsed = queryString.parse(nextProps.location.search);
    let ucms = parsed.units || [];

    if (!Array.isArray(ucms)) {
      ucms = [ucms];
    }

    ucms = ucms.map((u) => queryString.parse(u));

    let nextState = {
      ucms: ucms,
      compareMode: parsed.compare || prevState.compareMode,
    };

    return nextState;
  }

  registerCard = (card) => {
    card.current.positionName = _.debounce(
      this.positionName.bind(card.current),
      100
    );
    this.cards.push(card);
  };

  unregisterCard = (card) => {
    card.current.positionName = undefined;
    this.cards.splice(this.cards.indexOf(card), 1);
  };

  componentDidMount() {
    this.cardsContainer.current.onscroll = this.handleScroll.bind(this);
  }

  componentWillUnmount() {
    this.cardsContainer.current.onscroll = null;
  }

  positionName(top) {
    this.style.top = top;
    if (top !== "0") {
      this.style.background =
        "url(https://res.cloudinary.com/fishofstone/image/upload/f_auto,q_auto:low,w_256,h_192,c_fill/twwstats/api/6009914752132233841/ui/skins/default/parchment_texture.webp)";
      this.style.backgroundPosition = "center";
      this.style.zIndex = "5000";
    } else {
      this.style.background = "none";
      this.style.backgroundPosition = "center";
      this.style.zIndex = "0";
    }
  }

  handleScroll = (event) => {
    const top = event.target.scrollTop;

    window.requestAnimationFrame(() => {
      // Calculate new scrollTop positions
      // for left and right panes based on
      // DOM nodes and evt.target.scrollTop
      // and set it directly on DOM nodes
      this.cards.forEach((card) => {
        const node = card.current;
        if (top >= 165) {
          node.positionName(`${top - 165}px`);
        } else if (node.style.top && node.style.top !== "0") {
          node.positionName(`0`);
        }
      });
    });

    return true;
  };

  onCompareModeChange = (event) => {
    let parsed = queryString.parse(this.props.location.search);
    parsed.compare = event.target.value;

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: queryString.stringify(parsed),
    });
  };

  toggleUnitFinder = () => {
    this.setState({
      showUnitFinder: !this.state.showUnitFinder,
      initialVersion: null,
      initialFaction: null,
    });
  };

  clearUnits = () => {
    if (window.confirm("This will removes all Unit Cards. Are you sure?")) {
      let parsed = queryString.parse(this.props.location.search);
      parsed.units = [];

      this.props.history.push({
        pathname: this.props.location.pathname,
        search: queryString.stringify(parsed),
      });
    }
  };

  addUnitsCards = (version, units, closeDialog) => {
    let parsed = queryString.parse(this.props.location.search);
    parsed.units = parsed.units || [];

    if (!Array.isArray(parsed.units)) {
      parsed.units = [parsed.units];
    }

    units.forEach((unit) => {
      let ucm = new UnitCardMeta(version, unit.unit, unit.ror ? 9 : 0, null, 0);
      parsed.units.push(queryString.stringify(ucm));
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: queryString.stringify(parsed),
    });

    this.setState({
      showUnitFinder: this.state.showUnitFinder && !closeDialog,
    });
  };

  onReplaceUnit = (idx, key) => {
    let parsed = queryString.parse(this.props.location.search);
    if (!Array.isArray(parsed.units)) {
      parsed.units = [parsed.units];
    }

    // Update the key but leave the rest intact
    let toReplace = queryString.parse(parsed.units[idx]);
    toReplace.k = key;
    parsed.units[idx] = queryString.stringify(toReplace);

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: queryString.stringify(parsed),
    });
  };

  onRemoveUnitCard = (idx) => {
    let parsed = queryString.parse(this.props.location.search);
    if (!Array.isArray(parsed.units)) {
      parsed.units = [parsed.units];
    }

    parsed.units.splice(idx, 1);

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: queryString.stringify(parsed),
    });
  };

  onCloneUnitCard = (idx) => {
    let parsed = queryString.parse(this.props.location.search);
    if (!Array.isArray(parsed.units)) {
      parsed.units = [parsed.units];
    }

    const toClone = parsed.units[idx];

    parsed.units.splice(idx, 0, toClone);

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: queryString.stringify(parsed),
    });
  };

  onMoveUnitCard = (idx, target) => {
    let parsed = queryString.parse(this.props.location.search);
    if (!Array.isArray(parsed.units)) {
      parsed.units = [parsed.units];
    }

    array_move(parsed.units, idx, target);

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: queryString.stringify(parsed),
    });
  };

  onRankChanged = (idx, rank) => {
    let parsed = queryString.parse(this.props.location.search);
    if (!Array.isArray(parsed.units)) {
      parsed.units = [parsed.units];
    }

    let ucm = queryString.parse(parsed.units[idx]);
    ucm.r = rank;
    parsed.units[idx] = queryString.stringify(ucm);

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: queryString.stringify(parsed),
    });
  };

  onFatigueChanged = (idx, fatigue) => {
    let parsed = queryString.parse(this.props.location.search);
    if (!Array.isArray(parsed.units)) {
      parsed.units = [parsed.units];
    }

    let ucm = queryString.parse(parsed.units[idx]);
    ucm.f = fatigue;
    parsed.units[idx] = queryString.stringify(ucm);

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: queryString.stringify(parsed),
    });
  };

  onClickVersion = (version) => {
    this.setState({
      initialVersion: version,
      initialFaction: null,
      showUnitFinder: true,
    });
  };

  onClickFaction = ({ version, faction }) => {
    this.setState({
      initialVersion: version,
      initialFaction: faction,
      showUnitFinder: true,
    });
  };

  render() {
    return (
      <div id="units-cards-container">
        <div
          id="units-cards-controls"
          style={{
            gridRow: "1 / span 1",
            gridColumn: "1 / span 1",
            justifySelf: "center",
            display: "flex",
          }}
        >
          <select
            title="Comparison Mode"
            className="version-select"
            style={{ width: "auto" }}
            onChange={(e) => this.onCompareModeChange(e)}
            value={this.state.compareMode}
          >
            <option value={CompareModeEnum.None}>No Comparison</option>
            <option value={CompareModeEnum.First}>Compare to First</option>
            <option value={CompareModeEnum.Previous}>
              Compare to Previous
            </option>
          </select>
          <button onClick={this.toggleUnitFinder}>
            <FontAwesome name="plus" /> Add Unit(s)
          </button>
          {this.state.ucms.length > 0 && (
            <button onClick={this.clearUnits}>
              <FontAwesome name="trash-alt" /> Clear All
            </button>
          )}
        </div>
        <div
          id="units-cards-cards-container"
          className="flex-column"
          ref={this.cardsContainer}
        >
          <div
            id="units-cards-cards"
            style={{
              gridTemplateRows: `20px repeat(12, auto)`,
              gridTemplateColumns: `256px repeat(${
                this.state.ucms.length - 1
              }, auto)`,
              padding: "0px 8px 20px 8px",
              margin: "auto",
            }}
          >
            {this.state.ucms.length === 0 && (
              <p>Use the "Add Unit(s)" button above to add Units Cards</p>
            )}
            {this.state.ucms.length > 0 && (
              <Fragment>
                {this.state.ucms.map((u, i) => (
                  <UnitCard
                    key={`${i}-${queryString.stringify(u)}`}
                    left={
                      this.state.compareMode === CompareModeEnum.None
                        ? null
                        : this.state.compareMode === CompareModeEnum.First
                        ? i === 0
                          ? null
                          : this.state.ucms[0]
                        : this.state.compareMode === CompareModeEnum.Previous
                        ? i === 0
                          ? null
                          : this.state.ucms[i - 1]
                        : null
                    }
                    right={u}
                    index={i}
                    // containerScrollTop={this.state.scrollTop}
                    arrayLength={this.state.ucms.length}
                    onRankChanged={this.onRankChanged}
                    onFatigueChanged={this.onFatigueChanged}
                    onRemoveUnitCard={this.onRemoveUnitCard}
                    onMoveUnitCard={this.onMoveUnitCard}
                    onCloneUnitCard={this.onCloneUnitCard}
                    onReplaceUnit={this.onReplaceUnit}
                    registerCard={this.registerCard}
                    unregisterCard={this.unregisterCard}
                    onClickVersion={this.onClickVersion}
                    onClickFaction={this.onClickFaction}
                  />
                ))}
              </Fragment>
            )}
          </div>
        </div>
        <Modal
          className={{
            base: "twwstats_unit_finder",
            afterOpen: "after-open",
            beforeClose: "before-close",
          }}
          ariaHideApp={false}
          isOpen={this.state.showUnitFinder}
          onRequestClose={this.toggleUnitFinder}
          contentLabel="Unit Finder"
          closeTimeoutMS={300}
        >
          <SimpleDialog onClose={this.toggleUnitFinder}>
            <UnitFinder
              onConfirm={this.addUnitsCards}
              initialVersion={this.state.initialVersion}
              initialFaction={this.state.initialFaction}
            />
          </SimpleDialog>
        </Modal>
      </div>
    );
  }
}
