import GridControls from "./grid-controls-hoc";
import { FactionMultiselect } from "./factions-multiselect";

import createColumnDefs from "../colDefs/unit-coldefs";
import Unit from "../models/unit";
import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-apollo";
import _ from "lodash";
import commonUnitConstants from "../graphql/common-unit-constants-query-hoc";
import twwstatsOverlay from "../components/grid-helpers/overlay";
import unitsGraphQuery from "../graphql/units-graph-query-hoc";
import interpret, {
  extractSynonyms,
} from "../services/filter-query-interpreter";

import analyze from "../services/filter-query-analyzer";
import {
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  Legend,
} from "recharts";

const BATCH_SIZE = 500;
const fields = [
  "singleplayer_cost",
  "singleplayer_upkeep",
  "multiplayer_cost",
  "health",
  "health_per_entity",
  "barrier_health",
  "armour",
  "parry_chance",
  "damage_mod_all",
  "damage_mod_physical",
  "damage_mod_missile",
  "damage_mod_magic",
  "damage_mod_flame",
  "leadership",
  "speed",
  "charge_speed",
  "melee_attack",
  "primary_melee_weapon.melee_attack_interval",
  "is_high_threat",
  "primary_melee_weapon.splash_attack_target_size",
  "primary_melee_weapon.splash_attack_max_attacks",
  "melee_defence",
  "primary_melee_weapon.damage",
  "primary_melee_weapon.base_damage",
  "primary_melee_weapon.ap_damage",
  "primary_melee_weapon.bonus_v_large",
  "primary_melee_weapon.bonus_v_infantry",
  "charge_bonus",
  "primary_missile_weapon.ammo",
  "primary_missile_weapon.projectile.range",
  "primary_missile_weapon.damage",
  "primary_missile_weapon.projectile.base_damage",
  "primary_missile_weapon.projectile.ap_damage",
  "primary_missile_weapon.projectile.bonus_v_infantry",
  "primary_missile_weapon.projectile.bonus_v_large",
  "primary_missile_weapon.projectile.explosion.base_damage",
  "primary_missile_weapon.projectile.explosion.ap_damage",
  "primary_missile_weapon.projectile.explosion.detonation_radius",
  "primary_missile_weapon.projectile.shots_per_volley",
  "primary_missile_weapon.projectile.projectile_number",
  "primary_missile_weapon.projectile.category",
  "primary_missile_weapon.reload_time",
  "primary_missile_weapon.projectile.calibration_distance",
  "primary_missile_weapon.projectile.calibration_area",
  "primary_missile_weapon.projectile.penetration_entity_size_cap",
  "primary_missile_weapon.projectile.penetration_max_penetration",
  "mass",
  "ground_stat_effect_group",
  "fatigue_modifier",
];

const factionNameMapping = {
  names_vmp_vampire_counts: "Vampire Counts",
  names_lzd_lizardmen: "Lizardmen",
  names_def_dark_elves: "Dark Elves",
  names_tze_tzeentch: "Tzeentch",
  names_wef_wood_elves: "Wood Elves",
  names_cst_vampire_coast: "Vampire Coast",
  names_skv_skaven: "Skaven",
  names_dwf_dwarfs: "Dwarfs",
  names_chd_chaos_dwarfs: "Chaos Dwarfs",
  names_nor_norsca: "Norsca",
  names_ksl_kislev: "Kislev",
  names_brt_bretonnia: "Bretonnia",
  names_bst_beastmen: "Beastmen",
  names_nur_nurgle: "Nurgle",
  names_hef_high_elves: "High Elves",
  names_tmb_tomb_kings: "Tomb Kings",
  names_emp_empire: "The Empire",
  names_grn_greenskins: "Greenskins",
  names_ogr_ogre_kingdom: "Ogre Kingdoms",
  names_chs_chaos: "Warriors of Chaos",
  names_sla_slaanesh: "Slaanesh",
  names_cth_cathay: "Grand Cathay",
  names_kho_khorne: "Khorne",
};

// Helper function to convert a technical name to proper faction name
const getFactionName = (technicalName) => {
  return factionNameMapping[technicalName] || "Unknown Faction";
};

const dataClass = Unit;

const defaultSortData = (data) => {
  return data;
};

const renderTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    const faction = getFactionName(data.factions[0].name_group);

    return (
      <div
        style={{
          margin: 0,
          padding: 10,
          backgroundColor: "#fff",
          border: "1px solid #000",
        }}
      >
        <p style={{ color: "#000" }}>{faction}</p>
        <p style={{ color: "#000" }}>{data.name}</p>
        {payload.map((p) => (
          <p key={p.name} style={{ color: "#000" }}>
            <span>{p.name}:</span>
            {p.value}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const dataKey = "units";

export const Graph = ({
  dataLoaded,
  defaultColDef,
  history,
  location,
  onCellClicked,
  queryVariables,
  quickFilterText,
  rowHeight,
  rowKey,
  sortData,
  tww_version_right,
  WrappedComponent,
}) => {
  const [data, setData] = useState(null);
  const [right, setRight] = useState([]);
  const [rightReady, setRightReady] = useState(false);
  const [xAxis, setXAxis] = useState("melee_attack");
  const [yAxis, setYAxis] = useState("melee_defence");
  const [synonyms, setSynonyms] = useState(extractSynonyms(createColumnDefs()));
  const [selectedFactions, setSelectedFactions] = useState([]);
  const [useLocalDomain, setUseLocalDomain] = useState(true);

  const actualDataClass = dataClass;
  const actualSortData = sortData || defaultSortData;

  const qv = useMemo(() => {
    let qv = { ...queryVariables };
    if (qv.batch) {
      qv.offset = 0;
      qv.size = BATCH_SIZE;
    }
    return qv;
  }, [queryVariables]);

  const {
    data: rightData,
    error: rightError,
    loading: rightLoading,
    refetch: rightRefetch,
    variables: rightVariables,
  } = useQuery(unitsGraphQuery, {
    skip: !tww_version_right,
    variables: {
      tww_version: tww_version_right,
      ...qv,
    },
  });

  const {
    data: cucRightData,
    error: cucRightError,
    loading: cucRightLoading,
  } = useQuery(commonUnitConstants, {
    skip: !tww_version_right,
    variables: {
      tww_version: tww_version_right,
    },
  });

  // Query error handling
  useEffect(() => {
    if (rightError) {
      console.error(rightError);
    }
  }, [rightError]);

  // Reset right when version changes or queryVariables change
  useEffect(() => {
    setRight([]);
    setRightReady(false);
    setData(null);
  }, [qv, tww_version_right]);

  // Load right version data
  useEffect(() => {
    if (
      !rightReady &&
      rightData &&
      !rightLoading &&
      (!qv.batch || rightVariables.offset === right.length) &&
      rightData.tww.tww_version === tww_version_right
    ) {
      const newData = rightData.tww[dataKey];
      setRight((prev) => [...prev, ...newData]);

      if (qv.batch && newData.length === BATCH_SIZE) {
        rightRefetch({
          tww_version: tww_version_right,
          ...qv,
          offset: rightVariables.offset + BATCH_SIZE,
        });
      } else {
        setRightReady(true);
      }
    }
  }, [
    tww_version_right,
    rightData,
    rightLoading,
    rightReady,
    dataKey,
    qv,
    rightRefetch,
    right,
    rightVariables,
  ]);

  // Update the data once all required versions are ready
  useEffect(() => {
    if (!data) {
      if (rightReady) {
        if (right != null) {
          if (!cucRightLoading && cucRightData) {
            let d = right.map(
              (row) =>
                new actualDataClass(row, tww_version_right, cucRightData.tww)
            );
            d = actualSortData(d);
            d = dataLoaded ? dataLoaded(d) : d;
            d = d.filter(({ name }) => Boolean(name));

            setData(d);
          }
        }
      }
    }
  }, [
    rightReady,
    right,
    actualDataClass,
    actualSortData,
    dataLoaded,
    data,
    tww_version_right,
    cucRightData,
  ]);

  const ast_root = useMemo(() => analyze(quickFilterText), [quickFilterText]);

  // TODO Copy list of synonyms from the computed one in units-grid and create a static version of it for use here
  // TODO Implement interpreter based off the unit object instead of a ag-grid rowNode
  const applyFilter = (unit) => {
    return !quickFilterText || interpret(ast_root, unit, synonyms);
  };
  // const applyFilter = (unit) => {
  //   return (
  //     !quickFilterText ||
  //     (unit.name &&
  //       unit.name.toLowerCase().includes(quickFilterText.toLowerCase()))
  //   );
  // };
  const filtered = data
    ?.filter(applyFilter)
    ?.filter(
      ({ factions }) =>
        factions.length > 0 && selectedFactions.includes(factions[0].name_group)
    );
  const byFaction = _.groupBy(filtered, "factions[0].name_group");

  const xDomain = useMemo(() => {
    if (!data) return [0, 0];

    if (useLocalDomain) {
      const values = filtered.map((unit) => unit[xAxis]);
      return [_.min(values), _.max(values)];
    } else {
      const values = data.map((unit) => unit[xAxis]);
      return [_.min(values), _.max(values)];
    }
  }, [data, xAxis, useLocalDomain]);

  const yDomain = useMemo(() => {
    if (!data) return [0, 0];

    if (useLocalDomain) {
      const values = filtered.map((unit) => unit[yAxis]);
      return [_.min(values), _.max(values)];
    } else {
      const values = data.map((unit) => unit[yAxis]);
      return [_.min(values), _.max(values)];
    }
  }, [data, yAxis, useLocalDomain]);

  if (rightLoading || !data) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        dangerouslySetInnerHTML={{ __html: twwstatsOverlay }}
      ></div>
    );
  }

  return (
    <>
      <div style={{ display: "flex" }}>
        <select
          title="Select the Y axis"
          className="version-select"
          onChange={(event) => setYAxis(event.target.value)}
          value={yAxis}
        >
          {fields.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <select
          title="Select the X axis"
          className="version-select"
          onChange={(event) => setXAxis(event.target.value)}
          value={xAxis}
        >
          {fields.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <FactionMultiselect onSelected={setSelectedFactions} />
      </div>
      <ResponsiveContainer>
        <ScatterChart
          style={{ backgroundColor: "#fff" }}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid />
          <XAxis type="number" dataKey={xAxis} domain={xDomain}>
            <Label value={xAxis} offset={0} position="insideBottom" />
          </XAxis>
          <YAxis type="number" dataKey={yAxis} domain={yDomain}>
            <Label value={yAxis} offset={0} angle={-90} position="insideLeft" />
          </YAxis>
          <Tooltip
            cursor={{ strokeDasharray: "3 3" }}
            content={renderTooltip}
          />
          <Legend
            wrapperStyle={{
              background: "#f5f5f5",
            }}
          />
          {Object.keys(byFaction).map((faction) => {
            const isCathay = faction === "names_cth_cathay";
            const isHighElves = faction === "names_hef_high_elves";

            let primary = `#${byFaction[faction][0].factions[0].primary_colour_hex}`;
            let secondary = `#${byFaction[faction][0].factions[0].secondary_colour_hex}`;

            if (isCathay) {
              primary = "#ff0000";
              secondary = "#000000";
            }

            if (isHighElves) {
              primary = "#FFD700";
              secondary = "#000000";
            }

            return (
              <Scatter
                key={faction}
                name={getFactionName(faction)}
                data={byFaction[faction]}
                stroke={secondary}
                fill={primary}
              />
            );
          })}
        </ScatterChart>
      </ResponsiveContainer>
    </>
  );
};

export const UnitsGraph = (props) => (
  <GridControls
    gridClass="units-graph"
    {...props}
    no_compare
    WrappedComponent={Graph}
  />
);
