import React, { useState } from "react";
import styled from "styled-components";
import AbilityTooltipContainer from "./AbilityTooltipContainer";
import CardButton from "./CardButton";
import TwwTooltip from "./tww-tooltip";
import GenericIcon from "./genericicon";

const AbilityCard = ({ onRemoveCard, index, right, ...props }) => {
  const { v: tww_version } = right;

  const [ability, setAbility] = useState(null);

  const abilityLoaded = (ability) => setAbility(ability);

  const overpower =
    right && ability?.overpower_option?.key
      ? {
          ...right,
          k: ability?.overpower_option?.key,
        }
      : null;

  return (
    <Container>
      <Header>
        {ability && (
          <GenericIcon
            name={ability.name}
            desc={ability.key}
            height={38}
            width={38}
            icon={`ui/battle%20ui/ability_icons/${ability.icon_name.toLowerCase()}.webp`}
            tww_version={tww_version}
            noTooltip
          />
        )}
        <CardButton
          style={{ gridArea: "1 / 8 / span 1 / span 1", color: "darkRed" }}
          name="times"
          title="Remove"
          onClick={() => onRemoveCard(index)}
        />
      </Header>
      <AbilityTooltipContainer
        onAbilityLoaded={abilityLoaded}
        right={right}
        overpower={overpower}
        {...props}
      />
    </Container>
  );
};

export default AbilityCard;

const Container = styled.div`
  margin: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Header = styled(TwwTooltip)`
  background: rgba(255, 255, 255, 0.2);
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;
