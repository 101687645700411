import { Tooltip } from "react-tippy";
import { cloudinaryUrl } from "../services/common";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import AbilityQuery from "../graphql/ability-card-query-hoc";
import commonUnitConstants from "../graphql/common-unit-constants-query-hoc";
import Ability from "../models/ability";
import TwwTooltip, { Title, FlavorText } from "./tww-tooltip";
import styled from "styled-components";
import AbilityTypeIcon from "./abilitytypeicon";
import GenericIcon from "./genericicon";
import { processTemplatedText, isStatPercentage } from "../services/common";
import { PhaseDamageHealingDetails } from "./phaseicon";

const AbilityTooltip = ({
  singleColumn,
  right,
  left,
  apolloClient,
  className,
  onAbilityLoaded,
  ...props
}) => {
  const [rightAbility, setRightAbility] = useState(null);

  const {
    loading: loadingRightCuc,
    error: errorRightCuc,
    data: dataRightCuc,
  } = useQuery(commonUnitConstants, {
    variables: { tww_version: right.v },
    client: apolloClient,
  });

  const {
    loading: loadingRight,
    error: errorRight,
    data: dataRight,
  } = useQuery(AbilityQuery, {
    variables: { tww_version: right.v, id: right.k },
    client: apolloClient,
  });

  useEffect(() => {
    if (!loadingRightCuc && !loadingRight) {
      const rightAbility = new Ability(
        dataRight.tww.ability,
        dataRight.tww.tww_version,
        dataRightCuc.tww
      );
      setRightAbility(rightAbility);
      if (onAbilityLoaded) {
        onAbilityLoaded(rightAbility);
      }
    }
  }, [loadingRight, loadingRightCuc]);

  // const { loading: loadingLeftCuc, error: errorLeftCuc, data: dataLeftCuc } = useQuery(commonUnitConstants, {
  //   skip: !left,
  //   variables: left && { tww_version: left.v }
  // })

  // const { loading: loadingLeft, error: errorLeft, data: dataLeft } = useQuery(AbilityQuery, {
  //   skip: !left,
  //   variables: left && { tww_version: left.v, key: left.k }
  // })

  // if (!left) {
  if (!rightAbility) {
    return <p>Loading Ability Card {right.k}</p>;
  } else {
    return (
      <AbilityCardContent
        singleColumn={singleColumn}
        className={className}
        ability={rightAbility}
        cuc={dataRightCuc.tww}
        {...props}
      />
    );
  }
  // } else {
  //   if (loadingRightCuc || loadingRight || loadingLeftCuc || loadingLeft) {
  //     return (<p>Loading Unit Card {right.k}</p>);
  //   }
  //   else {
  //     let leftUnit = new Unit(dataLeft.tww.unit, dataLeft.tww.tww_version, dataLeftCuc.tww, dataLeft.tww.game_version, left.r, left.f);
  //     let rightUnit = new Unit(dataRight.tww.unit, dataRight.tww.tww_version, dataRightCuc.tww, dataRight.tww.game_version, right.r, right.f);
  //     rightUnit = deltaObject(leftUnit, rightUnit);

  //     return (
  //       <UnitCardContent unit={rightUnit} cuc={dataRightCuc.tww} {...props} />
  //     );
  //   }
  // }
};

const AbilityCardContent = ({
  singleColumn,
  ability,
  cuc,
  className,
  overpower,
  showCastIcon,
}) => {
  const {
    tww_version,
    special_ability,
    name,
    rarity,
    tooltip,
    type_icon,
    type_key,
    type_name,
    additional_ui_effects,
    phases,
    is_overcast,
  } = ability;
  const {
    active_time,
    mana_cost,
    recharge_time,
    target,
    invalid_targets,
    invalid_usages,
    effect_range,
    target_intercept_range,
    miscast_chance,
    projectile,
    vortex,
    auto_deactivate_flags,
    update_targets_every_frame,
    num_effected_friendly_units,
    num_effected_enemy_units,
  } = special_ability;

  const stat_effects = [...phases]
    .map((p) => p.statEffects)
    .flat()
    .map((e) => {
      const stat = cuc.unit_stat_localisations.find(
        (l) => l.stat_key === e.stat
      );
      const amount =
        e.how === "add" ? e.value : `${Math.round((e.value - 1) * 100, 10)}%`;
      const positive = e.how === "add" ? e.value > 0 : e.value > 1;
      const color = positive ? "green" : "red";
      return `[[col:${color}]]${positive ? "+" : ""}${amount}${
        isStatPercentage(e.stat) ? "%" : ""
      } ${stat?.onscreen_name || e.stat}[[/col]]`;
    });

  const attribute_effects = [...phases]
    .map((p) => p.attributeEffects)
    .flat()
    .map((e) => {
      const attribute = cuc.attributes.find((a) => a.key === e.attribute);
      const positive = e.attributeType === "positive";
      // const color = positive ? "green" : "red";
      // const arrow = positive
      //   ? "[[img:icon_arrow_up]][[/img]]"
      //   : "[[img:icon_arrow_down]][[/img]]";
      return `${attribute.imbued_effect_text}`;
    });

  const effects = [
    ...additional_ui_effects,
    ...stat_effects,
    ...attribute_effects,
  ].map((e, i) => (
    <div
      style={{ textAlign: "left" }}
      key={i}
      dangerouslySetInnerHTML={{
        __html: processTemplatedText(e, tww_version, cuc.ui_tagged_images),
      }}
    ></div>
  ));

  const phaseEffectText = (p) => {
    const mainEffect = processTemplatedText(
      p.onscreen_name.replace("[[/col]]", ` (${p.duration} seconds)[[/col]]`),
      tww_version,
      cuc.ui_tagged_images
    );
    const statEffects = p.statEffects
      .map((e) => {
        const stat = cuc.unit_stat_localisations.find(
          (l) => l.stat_key === e.stat
        );
        const amount =
          e.how === "add" ? e.value : `${Math.round((e.value - 1) * 100, 10)}%`;
        const positive = e.how === "add" ? e.value > 0 : e.value > 1;
        const color = positive ? "green" : "red";
        return `[[col:${color}]]${positive ? "+" : ""}${amount}${
          isStatPercentage(e.stat) ? "%" : ""
        } ${stat.onscreen_name}[[/col]]`;
      })
      .map((e) => processTemplatedText(e, tww_version, cuc.ui_tagged_images));

    const attributeEffects = p.attributeEffects
      .map((e) => {
        const attribute = cuc.attributes.find((a) => a.key === e.attribute);
        const positive = e.attributeType === "positive";
        const color = positive ? "green" : "red";
        const arrow = positive
          ? "[[img:icon_arrow_up]][[/img]]"
          : "[[img:icon_arrow_down]][[/img]]";
        return `[[col:${color}]]${arrow}${attribute.imbued_effect_text}[[/col]]`;
      })
      .map((e) => processTemplatedText(e, tww_version, cuc.ui_tagged_images));

    return [mainEffect, ...statEffects, ...attributeEffects]
      .filter(Boolean)
      .join("<br/>");
  };

  const imbue_magical =
    phases?.reduce((acc, p) => acc || p.imbue_magical, false) &&
    processTemplatedText(
      "[[img:ui/skins/default/modifier_icon_magical.webp]][[/img]] Magical Damage",
      tww_version,
      cuc.ui_tagged_images
    );
  const imbue_ignition =
    phases?.reduce((acc, p) => acc || p.imbue_ignition, false) &&
    processTemplatedText(
      "[[img:ui/skins/default/modifier_icon_flaming.webp]][[/img]] Fire Damage",
      tww_version,
      cuc.ui_tagged_images
    );
  const imbue_effect = phases
    ?.filter((p) => p.imbuedEffect)
    .map((p) => phaseEffectText(p.imbuedEffect));
  const imbued = [imbue_magical, imbue_ignition, ...imbue_effect]
    .filter(Boolean)
    .join(", ");

  const contact = vortex?.phase && phaseEffectText(vortex.phase);

  const ColComponent = singleColumn ? Column : ColumnHalf;

  const castIcon = overpower
    ? "ui/skins/default/icon_spell_browser_charge2.webp"
    : "ui/skins/default/icon_spell_browser_charge1.webp";

  return (
    <div style={{ maxWidth: "1000px", width: "100%" }}>
      <Title rarity={rarity}>
        {showCastIcon ? (
          <img
            src={`${cloudinaryUrl()}/api/${
              tww_version || process.env.REACT_APP_TWWSTATS_LATEST_VERSION
            }/${castIcon}`}
            alt="cast"
          />
        ) : null}
        <div style={{ flexGrow: 1, display: "flex" }}>{name}</div>
        {mana_cost > 0 && (
          <div style={{ flexGrow: 0, display: "flex", padding: "0 2px" }}>
            <GenericIcon
              height={20}
              width={20}
              noTooltip
              name="mana"
              icon="ui/skins/default/icon_mana.webp"
            />
            {mana_cost}
          </div>
        )}
        {recharge_time > 0 && (
          <div style={{ flexGrow: 0, display: "flex", padding: "0 2px" }}>
            <GenericIcon
              height={20}
              width={20}
              noTooltip
              name="mana"
              icon="ui/skins/default/icon_cooldown.webp"
            />
            {recharge_time}
          </div>
        )}
      </Title>
      <Data>
        <ColComponent>
          <DataRow label="Type:">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex" }}>
                <AbilityTypeIcon
                  icon={type_icon}
                  name={type_key}
                  size={16}
                  desc={""}
                  tww_version={tww_version}
                />
                <div style={{ paddingLeft: "4px" }}>{type_name}</div>
              </div>
              {update_targets_every_frame && effect_range > 0 && (
                <div>Only acts on targets when in area</div>
              )}
              {!update_targets_every_frame && effect_range > 0 && (
                <div>Instantly affects targets in area</div>
              )}
            </div>
          </DataRow>
          {active_time !== 0 && type_key !== "wh_type_magic_missile" && (
            <DataRow label="Duration:">
              {active_time === -1 ? "Constant" : `${active_time} seconds`}
            </DataRow>
          )}
          <DataRow label="Target:">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div>
                {target.length ? target.reverse().join(", ") : "Around self"}
              </div>
              {!!effect_range > 0 && !!num_effected_friendly_units && (
                <div>Affects allies in range</div>
              )}
              {!!effect_range && !!num_effected_enemy_units && (
                <div>Affects ennemies in range</div>
              )}
              {!!target_intercept_range && (
                <div>
                  <GenericIcon
                    height={16}
                    width={16}
                    noTooltip
                    name="mana"
                    icon="ui/skins/default/icon_distance_to_target.webp"
                  />
                  {target_intercept_range === -1
                    ? "Map-wide"
                    : `${target_intercept_range}m`}
                </div>
              )}
            </div>
          </DataRow>
          {!!auto_deactivate_flags?.filter((f) => f.alt_description).length && (
            <DataRow label="Enabled if:">
              <div
                dangerouslySetInnerHTML={{
                  __html: auto_deactivate_flags
                    .filter((f) => f.alt_description)
                    .map((f) =>
                      processTemplatedText(
                        f.alt_description,
                        tww_version,
                        cuc.ui_tagged_images
                      )
                    )
                    .join(", "),
                }}
              />
            </DataRow>
          )}
          {/* Some mods don't provide the alt_description */}
          {!!auto_deactivate_flags?.filter((f) => !f.alt_description)
            .length && (
            <DataRow label="Disabled if:">
              <div
                dangerouslySetInnerHTML={{
                  __html: auto_deactivate_flags
                    .filter((f) => !f.alt_description)
                    .map((f) =>
                      processTemplatedText(
                        f.flag_description,
                        tww_version,
                        cuc.ui_tagged_images
                      )
                    )
                    .join(", "),
                }}
              />
            </DataRow>
          )}
          {/* Only for breath for now... could be expanded to other types but it's more complicated */}
          {(vortex?.damage || vortex?.ap_damage) &&
          type_key === "wh_type_breath" ? (
            <DataRow label="Damage:">
              <div>
                {vortex.damage ? (
                  <>
                    <Tooltip
                      position="top"
                      arrow="true"
                      style={{ display: "flex" }}
                      html={
                        <div
                          style={{
                            fontFamily: '"Open Sans", sans-serif',
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Base Damage: {vortex.damage}
                          </span>
                        </div>
                      }
                    >
                      <div>
                        {vortex.damage}
                        <img
                          src={`${cloudinaryUrl()}/api/${
                            tww_version ||
                            process.env.REACT_APP_TWWSTATS_LATEST_VERSION
                          }/ui/skins/default/icon_stat_damage.webp`}
                          width="20"
                        />
                      </div>
                    </Tooltip>
                  </>
                ) : null}
                {vortex.damage && vortex.ap_damage ? <span> + </span> : null}
                {vortex.damage_ap ? (
                  <>
                    <Tooltip
                      position="top"
                      arrow="true"
                      style={{ display: "flex" }}
                      html={
                        <div
                          style={{
                            fontFamily: '"Open Sans", sans-serif',
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            AP Damage: {vortex.damage_ap}
                          </span>
                        </div>
                      }
                    >
                      <div>
                        {vortex.damage_ap}
                        <img
                          src={`${cloudinaryUrl()}/api/${
                            tww_version ||
                            process.env.REACT_APP_TWWSTATS_LATEST_VERSION
                          }/ui/skins/default/modifier_icon_armour_piercing.webp`}
                          width="20"
                        />
                      </div>
                    </Tooltip>
                  </>
                ) : null}
              </div>
            </DataRow>
          ) : null}
          {!!invalid_usages.length && (
            <DataRow label="Cannot use if:">
              <div
                dangerouslySetInnerHTML={{
                  __html: invalid_usages
                    .map((u) =>
                      processTemplatedText(u, tww_version, cuc.ui_tagged_images)
                    )
                    .join(", "),
                }}
              />
            </DataRow>
          )}
          {!!invalid_targets.length && (
            <DataRow label="Cannot target if:">
              <div
                dangerouslySetInnerHTML={{
                  __html: invalid_targets
                    .map((u) =>
                      processTemplatedText(u, tww_version, cuc.ui_tagged_images)
                    )
                    .join(", "),
                }}
              />
            </DataRow>
          )}
          {!!effect_range && (
            <DataRow label="Area of Effect:">
              {effect_range === -1 ? "Map-wide" : `${effect_range}m`}
            </DataRow>
          )}
          {/* {!!projectile?.range && (
            <DataRow label="Projectile Range:">{projectile.range}m</DataRow>
          )} */}
          {!!miscast_chance && (
            <DataRow label="Miscast Chance:">
              <div className="color-magic">{miscast_chance * 100}%</div>
            </DataRow>
          )}
        </ColComponent>
        <ColComponent>
          {!!effects?.length && (
            <DataRow
              direction="column"
              label="Effects:"
              alignItems="flex-start"
            >
              {effects}
            </DataRow>
          )}
          {imbued && (
            <DataRow label="Imbued:">
              <div
                dangerouslySetInnerHTML={{
                  __html: imbued,
                }}
              />
            </DataRow>
          )}
          {!!contact && (
            <DataRow label="Contacts:">
              <div
                style={{ textAlign: "left" }}
                dangerouslySetInnerHTML={{
                  __html: contact,
                }}
              />
            </DataRow>
          )}
        </ColComponent>
      </Data>
      <FlavorText>{tooltip}</FlavorText>
      {/* {projectile && (
        <TwwTooltip>
          <Column>
            <DataRow label="Range:">{projectile.range}m</DataRow>
            <DataRow label="Shots per Volley:">
              {projectile.shots_per_volley}
            </DataRow>
            <DataRow label="Base Damage:">{projectile.base_damage}</DataRow>
            <DataRow label="AP Damage:">{projectile.ap_damage}</DataRow>
            <DataRow label="Bonus vs Infantry:">
              {projectile.bonus_v_infantry}
            </DataRow>
            <DataRow label="Bonus vs Large:">
              {projectile.bonus_v_large}
            </DataRow>
            <DataRow label="Explosion Radius:">
              {projectile.calibration_area}m
            </DataRow>
            <DataRow label="Explosion Base Damage:">
              {projectile.calibration_distance}m
            </DataRow>
            <DataRow label="Explosion AP Damage:">
              {projectile.ap_ratio}
            </DataRow>
          </Column>
        </TwwTooltip>
      )} */}
      {phases.map((p, i) => (
        <TwwTooltip key={i}>
          <PhaseDamageHealingDetails phase={p} />
        </TwwTooltip>
      ))}
      {[...phases]
        .map((p) => p.attributeEffects)
        .flat()
        .map((e, i) => {
          const attribute = cuc.attributes.find((a) => a.key === e.attribute);
          return (
            <TwwTooltip key={i}>
              <Column>
                <Row>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: processTemplatedText(
                        `[[img:ui/battle ui/ability_icons/${attribute.key}.webp]][[/img]]`,
                        tww_version,
                        cuc.ui_tagged_images,
                        22
                      ),
                    }}
                  />
                  <div
                    style={{ paddingLeft: "4px", textAlign: "left" }}
                    dangerouslySetInnerHTML={{
                      __html: processTemplatedText(
                        attribute.bullet_text,
                        tww_version,
                        cuc.ui_tagged_images
                      ),
                    }}
                  ></div>
                </Row>
              </Column>
            </TwwTooltip>
          );
        })}
    </div>
  );
};

const Row = styled.div`
  display: flex;
  margin-bottom: 8px;
  line-height: 16px;
`;

const DataRow = ({ label, children, direction = "row" }) => (
  <Row>
    <Label>{label}</Label>
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: direction,
      }}
    >
      {children}
    </div>
  </Row>
);

const Label = styled.div`
  width: 125px;
  flex: 0 0 auto;
  text-align: left;
`;

const Data = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  box-sizing: border-box;
  font-family: serif;
  color: rgba(255, 248, 215);
  font-size: 16px;
`;

const ColumnHalf = styled(Column)`
  @media (min-width: 768px) {
    width: 50%;
    min-width: 250px;
  }
`;

export default AbilityTooltip;
