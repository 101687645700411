import _ from "lodash";
import React, { Component, Fragment } from "react";
import { useQuery } from "react-apollo";
import FontAwesome from "react-fontawesome";
import { Tooltip } from "react-tippy";
import commonUnitConstants from "../graphql/common-unit-constants-query-hoc";
import UnitQuery from "../graphql/unit-card-query-hoc";
import Unit from "../models/unit";
import { cloudinaryUrl, deltaObject } from "../services/common";
import { globalSettings, isInvertedDelta } from "../services/twwstats-services";
import AbilityIcon from "./AbilityIcon";
import AttributeIcon from "./attributeicon";
import CardButton from "./CardButton";
import CardCell from "./cardcell";
import CategoryIcon from "./categoryicon";
import FlamingIcon from "./flamingattackicon";
import GenericIcon from "./genericicon";
import getFrameworkComponents from "./grid-helpers/renderers";
import MagicIcon from "./magicattackicon";
import PhaseIcon from "./phaseicon";
import ShieldIcon from "./shieldicon";
import TooltipDesc from "../components/tooltipdesc";
import { BonusVsLargeTooltip } from "./tooltips/BonusVsLargeTooltip";
import { BonusVsInfantryTooltip } from "./tooltips/BonusVsInfantryTooltip";
import { ChargeBonusTooltip } from "./tooltips/ChargeBonusTooltip";

const parseField = (unit, field) => {
  var result = unit;
  field.split(".").forEach((f) => {
    if (result) {
      if (result[f] === false) {
        result = "no";
      } else if (result[f] === true) {
        result = "yes";
      } else {
        result = result[f] || 0;
      }
    }
  });
  return result;
};

const CardBox = (props) => (
  <div
    className={`card_box ${props.className || ""}`}
    style={{ ...props.style }}
  >
    {props.children}
  </div>
);

const CardStatContent = (props) => {
  const value = props.value ?? parseField(props.unit, props.field);
  const delta = props.unit[`delta_${props.field}`];

  return (
    <Fragment>
      <div
        style={{
          width: "22px",
          padding: "0 6px 0 0",
          flexGrow: "0",
          flexShrink: "0",
          marginLeft: `${(props.indentLevel || 0) * 16}px`,
        }}
      >
        {props.icon ? (
          <img
            width="22px"
            alt={props.name}
            src={`${cloudinaryUrl(props.cloudinaryOptions)}/api/${
              process.env.REACT_APP_TWWSTATS_LATEST_VERSION
            }/${props.icon}`}
          />
        ) : (
          <FontAwesome name={props.fa} color={props.facolor} />
        )}
      </div>
      <span
        style={{
          flexGrow: "1",
          fontStyle: props.indentLevel > 0 ? "italic" : "",
        }}
      >
        {props.name}
      </span>
      {props.icons}
      <span
        style={{
          flexGrow: "0",
          textAlign: "right",
          fontStyle: props.indentLevel > 0 ? "italic" : "",
        }}
      >
        {value}
      </span>
      {delta && (
        <span
          style={{
            display: "inline-block",
            position: "absolute",
            left: "250px",
            fontStyle: props.indentLevel > 0 ? "italic" : "",
          }}
          className={
            (!isInvertedDelta(props.field) && delta > 0) ||
            (isInvertedDelta(props.field) && delta < 0)
              ? "delta delta_positive"
              : "delta delta_negative"
          }
        >{`${delta > 0 ? "+" : ""}${delta}`}</span>
      )}
    </Fragment>
  );
};

const CardStat = (props) => {
  if (props.tooltip) {
    return (
      <Tooltip
        position="left"
        arrow="true"
        interactive={true}
        unmountHTMLWhenHide={true}
        useContext={true}
        trigger="click"
        // distance={10 + (props.indentLevel || 0) * -16} // Messes things up when the tooltip needs to be rendered right
        style={{
          display: "flex",
          cursor: "help",
          position: "relative",
          width: "100%",
          margin: "0 0 0px 0",
          height: "23px",
          alignItems: "center",
        }}
        html={
          <div
            style={{
              fontFamily: '"Open Sans", sans-serif',
            }}
          >
            <p style={{ margin: "0", fontWeight: "bold" }}>{props.name}</p>
            {props.tooltip}
          </div>
        }
      >
        <CardStatContent {...props} />
      </Tooltip>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          position: "relative",
          width: "100%",
          margin: "0 0 0px 0",
          height: "23px",
          alignItems: "center",
          cursor: "default",
        }}
      >
        <CardStatContent {...props} />
      </div>
    );
  }
};

const MountPicker = (props) => {
  return (
    <Tooltip
      position="top"
      arrow="true"
      trigger="click"
      interactive={true}
      unmountHTMLWhenHide={true}
      style={{ display: "inline-flex", cursor: "pointer", ...props.style }}
      html={
        <div
          style={{
            fontFamily: '"Open Sans", sans-serif',
          }}
        >
          <Fragment>
            <img
              onClick={() => {
                props.onChange(props.battle_mounts[0].base_unit);
              }}
              style={{ cursor: "pointer" }}
              alt={"On Foot"}
              src={`${cloudinaryUrl()}/api/7020588859072995689/ui/skins/default/icon_cross.webp`}
            />
            {props.battle_mounts.map((m, i) => (
              <img
                onClick={() => props.onChange(m.mounted_unit)}
                style={{ cursor: "pointer" }}
                key={i}
                alt={m.mount_name}
                title={m.mount_name}
                src={`${cloudinaryUrl()}/api/${
                  props.tww_version
                }/${m.icon_name.replace(".png", ".webp")}`}
              />
            ))}
          </Fragment>
        </div>
      }
    >
      {props.current ? (
        <img
          alt={props.current.mount_name}
          src={`${cloudinaryUrl()}/api/${
            props.tww_version
          }/${props.current.icon_name.replace(".png", ".webp")}`}
        />
      ) : (
        <img
          alt={"On Foot"}
          src={`${cloudinaryUrl()}/api/7020588859072995689/ui/skins/default/icon_cross.webp`}
        />
      )}
    </Tooltip>
  );
};

const RankPicker = ({ rank, ror, caste, onChange, style }) => {
  const effectiveRank = ror ? 9 : rank;
  const name = `Rank ${effectiveRank}`;

  const renderTooltip = (name, ror, caste, onChange) => {
    if (ror) {
      return (
        <Fragment>
          <p style={{ fontWeight: "bold" }}>Regiment of Renown</p>
          <p>Regiments of Renown cannot change Rank</p>
        </Fragment>
      );
    } else if (
      caste.toLowerCase() === "lord" ||
      caste.toLowerCase() === "hero"
    ) {
      return (
        <Fragment>
          <p style={{ fontWeight: "bold" }}>{caste}</p>
          <p>{caste} units cannot change rank</p>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <img
            onClick={() => {
              onChange(0);
              return false;
            }}
            style={{ cursor: "pointer" }}
            alt={name}
            src={`${cloudinaryUrl(["w_24", "h_24"])}/api/${
              process.env.REACT_APP_TWWSTATS_LATEST_VERSION
            }/ui/skins/default/build_frame_cross.webp`}
          />
          {Array.from(Array(9)).map((e, i) => (
            <img
              onClick={() => onChange(i + 1)}
              style={{ cursor: "pointer" }}
              key={i}
              alt={`Rank ${i + 1}`}
              src={`${cloudinaryUrl()}/api/${
                process.env.REACT_APP_TWWSTATS_LATEST_VERSION
              }/ui/skins/default/experience_${i + 1}.webp`}
            />
          ))}
        </Fragment>
      );
    }
  };

  return (
    <Tooltip
      position="top"
      arrow="true"
      trigger="click"
      interactive={true}
      unmountHTMLWhenHide={true}
      style={{ display: "inline-flex", cursor: "pointer", ...style }}
      html={
        <div
          style={{
            fontFamily: '"Open Sans", sans-serif',
          }}
        >
          {renderTooltip(name, ror, caste, onChange)}
        </div>
      }
    >
      {effectiveRank === 0 ? (
        <span style={{ ...style }}>Rank 0</span>
      ) : (
        <img
          alt={name}
          src={`${cloudinaryUrl()}/api/${
            process.env.REACT_APP_TWWSTATS_LATEST_VERSION
          }/ui/skins/default/experience_${effectiveRank}.webp`}
        />
      )}
    </Tooltip>
  );
};

const fatigues = [
  {
    key: 0,
    name: "Fresh",
    // color: '5cae22'
    color: "4f951d",
  },
  {
    key: 1,
    name: "Active",
    // color: '5cae22'
    color: "4f951d",
  },
  {
    key: 2,
    name: "Winded",
    color: "d26622",
  },
  {
    key: 3,
    name: "Tired",
    color: "d26622",
  },
  {
    key: 4,
    name: "Very Tired",
    color: "f54a3a",
  },
  {
    key: 5,
    name: "Exhausted",
    color: "f54a3a",
  },
];

const Fatigue = (props) => {
  const f = props.fatigue;
  return (
    <Fragment>
      <div
        style={{
          fontFamily: "serif",
          fontWeight: "bold",
          cursor: "pointer",
          display: "inline-flex",
          alignItems: "center",
        }}
        onClick={() => {
          props.onChange && props.onChange(f.key);
        }}
      >
        <img
          alt={f.name}
          src={`${cloudinaryUrl([
            "e_colorize:50",
            `co_rgb:${f.color}`,
          ])}/api/7020588859072995689/ui/skins/default/fatigue.webp`}
        />
        <span style={{ color: `#${f.color}` }}>{f.name}</span>
      </div>
    </Fragment>
  );
};
const FatiguePicker = (props) => {
  return (
    <Tooltip
      position="top"
      arrow="true"
      trigger="click"
      interactive={true}
      unmountHTMLWhenHide={true}
      style={{ display: "inline-flex", cursor: "pointer", ...props.style }}
      html={
        <div className="flex-column">
          <Fragment>
            {fatigues.map((f, i) => (
              <Fatigue key={i} fatigue={f} onChange={props.onChange} />
            ))}
          </Fragment>
        </div>
      }
    >
      <Fatigue fatigue={fatigues[props.fatigue]} />
    </Tooltip>
  );
};

const BulletPoint = ({ bullet, tww_version, icon_mappings }) => {
  const icon =
    bullet.state === "positive"
      ? "ui/skins/default/arrow_increase_1.webp"
      : "ui/skins/default/arrow_decrease_1.webp";
  const color =
    bullet.state === "positive"
      ? "rgba(25, 91, 25, 1)"
      : "rgba(133, 34, 40, 1)";

  const imgRegex = /\[\[img:(.*?)\]\]\[\[\/img\]\]/g;
  const tooltip =
    bullet.tooltip &&
    bullet.tooltip.replace(imgRegex, (match, p1, offset, string) => {
      const mapping = _.find(icon_mappings, { key: p1 });
      const url = (mapping && mapping.image_path) || p1;
      return `<img style='max-height: 22px; max-width: 22px;' src='${cloudinaryUrl()}/api/${tww_version}/${url.replace(
        ".png",
        ".webp"
      )}'/>`;
    });
  return (
    <Tooltip
      position="left"
      arrow="true"
      unmountHTMLWhenHide={true}
      style={{
        display: "flex",
        alignItems: "start",
        lineHeight: "18px",
        cursor: "default",
      }}
      html={
        <div
          style={{
            fontFamily: '"Open Sans", sans-serif',
          }}
        >
          <p style={{ fontWeight: "bold", margin: "0" }}>
            {bullet.onscreen_name}
          </p>
          <p dangerouslySetInnerHTML={{ __html: tooltip }} />
        </div>
      }
    >
      <img
        alt={bullet.state}
        src={`${cloudinaryUrl()}/api/${
          process.env.REACT_APP_TWWSTATS_LATEST_VERSION
        }/${icon.replace(".png", ".webp")}`}
      />
      <span style={{ color, marginLeft: "2px" }}> {bullet.onscreen_name}</span>
    </Tooltip>
  );
};

// 'name' is not currently used
const MissileWeaponCardContent = ({ name, unit, field }) => {
  return (
    <>
      {globalSettings().show_secondary_missile_weapon === "true" ? (
        <p>{name} Missile Weapon</p>
      ) : null}
      <CardStat
        icon="ui/skins/default/icon_stat_ammo.webp"
        name="Ammunition"
        unit={unit}
        field={`${field}.ammo`}
      />
      <CardStat
        icon="ui/skins/default/icon_stat_range.webp"
        name="Range"
        unit={unit}
        field={`${field}.projectile.range`}
      />
      <CardStat
        icon="ui/skins/default/icon_stat_ranged_damage.webp"
        name="Missile Damage"
        unit={unit}
        field={`${field}.damage`}
        icons={
          <>
            <FlamingIcon
              value={
                unit.primary_missile_weapon &&
                unit.primary_missile_weapon.ignition_amount
              }
            />
            <MagicIcon
              value={
                unit.primary_missile_weapon &&
                unit.primary_missile_weapon.is_magical
              }
            />
            <PhaseIcon
              phase={
                unit.primary_missile_weapon && unit.primary_missile_weapon.phase
              }
            />
          </>
        }
        tooltip={<p>Missile Damage (avg. over 10s)</p>}
      />
      <CardStat
        indentLevel={1}
        icon="ui/skins/default/icon_stat_ranged_damage.webp"
        name="Missile Base Damage"
        unit={unit}
        field={`${field}.projectile.base_damage`}
      />
      <CardStat
        indentLevel={1}
        icon="ui/skins/default/modifier_icon_armour_piercing_ranged.webp"
        name="Missile AP Damage"
        unit={unit}
        field={`${field}.projectile.ap_damage`}
      />
      <CardStat
        indentLevel={1}
        icon="ui/skins/default/modifier_icon_bonus_vs_infantry.webp"
        name="Bonus vs. Infantry"
        unit={unit}
        field={`${field}.projectile.bonus_v_infantry`}
      />
      <CardStat
        indentLevel={1}
        icon="ui/skins/default/modifier_icon_bonus_vs_large.webp"
        name="Bonus vs. Large"
        unit={unit}
        field={`${field}.projectile.bonus_v_large`}
      />
      <CardStat
        indentLevel={1}
        icon="ui/skins/default/icon_stat_ranged_damage.webp"
        name="Explosion Base Dmg."
        unit={unit}
        field={`${field}.projectile.explosion.base_damage`}
      />
      <CardStat
        indentLevel={1}
        icon="ui/skins/default/modifier_icon_armour_piercing_ranged.webp"
        name="Explosion AP Dmg."
        unit={unit}
        field={`${field}.projectile.explosion.ap_damage`}
      />
      <CardStat
        indentLevel={1}
        fa="street-view"
        name="Detonation Radius"
        unit={unit}
        field={`${field}.projectile.explosion.detonation_radius`}
      />
      <CardStat
        indentLevel={1}
        icon="ui/skins/default/icon_status_firing_24px.webp"
        name="Shots Per Volley"
        unit={unit}
        value={Math.max(
          parseField(unit, `${field}.projectile.shots_per_volley`),
          parseField(unit, `${field}.projectile.burst_size`)
        )}
      />
      <CardStat
        indentLevel={1}
        icon="ui/skins/default/icon_status_firing_24px.webp"
        name="Projectile Number"
        unit={unit}
        field={`${field}.projectile.projectile_number`}
      />
      <CardStat
        indentLevel={1}
        icon="ui/skins/default/icon_status_firing_24px.webp"
        name="Projectile Category"
        unit={unit}
        field={`${field}.projectile.category`}
        tooltip={
          <Fragment>
            <p>
              Some categories like artillery and misc ignore shields. Artillery
              also has a separate morale penalty.
            </p>
          </Fragment>
        }
      />
      <CardStat
        indentLevel={1}
        icon="ui/skins/default/icon_cooldown.webp"
        name="Reload Time"
        unit={unit}
        field={`${field}.reload_time`}
      />
      <CardStat
        indentLevel={1}
        icon="ui/skins/default/spacebar_fire_arc.webp"
        cloudinaryOptions={["x_11", "y_2", "w_22", "h_22", "c_crop"]}
        name="Total Accuracy"
        unit={unit}
        field={`${field}.total_accuracy`}
        tooltip={
          <Fragment>
            <p>Accuracy + Marksmanship Bonus</p>
            <p>
              Note that other elements such as the Calibration Distance,
              Calibration Area, etc. also impact wether or not a ranged unit
              hits its target.
            </p>
          </Fragment>
        }
      />
      <CardStat
        indentLevel={1}
        icon="ui/skins/default/icon_distance_to_target.webp"
        cloudinaryOptions={["w_22", "h_22"]}
        name="Calibration Distance"
        unit={unit}
        field={`${field}.projectile.calibration_distance`}
      />
      <CardStat
        indentLevel={1}
        icon="ui/skins/default/help_page_drag.webp"
        cloudinaryOptions={["w_22", "h_22"]}
        name="Calibration Area"
        unit={unit}
        field={`${field}.projectile.calibration_area`}
      />
      <CardStat
        indentLevel={1}
        icon="ui/skins/default/icon_status_firing_24px.webp"
        cloudinaryOptions={["w_22", "h_22"]}
        name="Pen. Size Cap"
        unit={unit}
        field={`${field}.projectile.penetration_entity_size_cap`}
        tooltip={
          <TooltipDesc title="Projectile Penetration Entity Size Cap">
            <p>Size of entity that will stop this projectile immediately.</p>
          </TooltipDesc>
        }
      />
      <CardStat
        indentLevel={1}
        icon="ui/skins/default/icon_status_firing_24px.webp"
        cloudinaryOptions={["w_22", "h_22"]}
        name="Max Penetration"
        unit={unit}
        field={`${field}.projectile.penetration_max_penetration`}
        tooltip={
          <TooltipDesc title="Projectile Penetration Max Penetration">
            <p>
              Penetration stat. A higher number means this projectile passes
              through more penetration points before being stopped. Check the
              battle entities table to see how many penetration points a single
              entity has.
            </p>
          </TooltipDesc>
        }
      />
    </>
  );
};

class UnitCardContent extends Component {
  render() {
    const gridColumn = `${this.props.index + 1} / span 1`;
    const unit = this.props.unit;
    const { FactionsRenderer, GroundStatEffectGroupRenderer } =
      getFrameworkComponents();
    // const namePosition = this.props.containerScrollTop > 165 ? 'fixed' : 'relative';

    let rowIdx = 1;

    const passiveAbilities = unit.abilities.filter(
      ({ unit_special_ability: { passive } }) => passive
    );
    const activeAbilities = unit.abilities.filter(
      ({ unit_special_ability: { passive } }) => !passive
    );

    return (
      <Fragment>
        {/* Parchment Background */}
        <div
          className="parchment"
          style={{
            gridRow: `${rowIdx++} / -1`,
            gridColumn: gridColumn,
          }}
        ></div>

        {/* Image, Game Version, Faction(s) Container */}
        <CardCell
          row={rowIdx++}
          col={gridColumn}
          style={{
            alignSelf: "stretch",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "8px",
          }}
        >
          {/* Image */}
          <div
            style={{
              height: "130px",
              width: "60px",
              textAlign: "center",
              position: "relative",
            }}
          >
            <img
              height={130}
              alt={`unit`}
              src={`${cloudinaryUrl()}/api/${
                unit.tww_version
              }/${unit.unit_card.replace(".png", ".webp")}`}
            />
            <img
              alt={"overlay"}
              src={`${cloudinaryUrl()}/api/7020588859072995689/ui/skins/default/unit_card_frame_plain.webp`}
              style={{ position: "absolute", top: "0", left: "0" }}
            />
            {unit.ror ? (
              <img
                alt={"overlay"}
                src={`${cloudinaryUrl()}/api/7020588859072995689/ui/skins/default/unit_renown_unlocked_frame.webp`}
                style={{ position: "absolute", top: "-35px", left: "-43px" }}
              />
            ) : null}
          </div>

          {/* Game Version */}
          <div
            style={{
              width: "100px",
              textAlign: "center",
              position: "absolute",
              bottom: "2px",
              left: "0",
              cursor: "pointer",
            }}
            className="flex-column"
            onClick={() => this.props.onClickVersion(unit.tww_version)}
          >
            <img
              alt={unit.version_name}
              style={{
                position: "relative",
                margin: "2px 0 0 0",
                width: "64px",
              }}
              src={`${cloudinaryUrl(["w_128"])}/api/${
                unit.tww_version
              }/ui/skins/default/fe_logo.webp`}
            />
            <p
              style={{
                position: "relative",
                margin: "0px",
                color: "#af5b11",
                fontStyle: "italic",
                padding: "0px 4px",
              }}
            >
              {unit.version_name}
            </p>
          </div>

          {/* Faction(s) */}
          <div
            style={{
              width: "64px",
              height: "64px",
              position: "absolute",
              bottom: "-10px",
              right: "19px",
              alignContent: "flex-end",
              justifyContent: "space-evenly",
            }}
            className="flex-row"
          >
            <FactionsRenderer
              value={unit.factions}
              tww_version={unit.tww_version}
              onClickFaction={this.props.onClickFaction}
            />
            <span style={{ width: "64px", textAlign: "center" }}>
              {unit.tier ? `Tier ${unit.tier}` : unit.caste}
            </span>
          </div>

          <div
            style={{
              position: "absolute",
              top: "0px",
              display: "grid",
              gridTemplate:
                "30px 30px / auto 30px 30px 30px 60px 30px 30px 30px auto",
            }}
          >
            {this.props.index > 0 && (
              <CardButton
                style={{ gridArea: "2 / 3 / span 1 / span 1" }}
                name="angle-double-left"
                onClick={() => this.props.onMoveUnitCard(this.props.index, 0)}
              />
            )}
            {this.props.index > 0 && (
              <CardButton
                style={{ gridArea: "2 / 4 / span 1 / span 1" }}
                name="caret-left"
                onClick={() =>
                  this.props.onMoveUnitCard(
                    this.props.index,
                    this.props.index - 1
                  )
                }
              />
            )}
            {this.props.index < this.props.arrayLength - 1 && (
              <CardButton
                style={{ gridArea: "2 / 6 / span 1 / span 1" }}
                name="caret-right"
                onClick={() =>
                  this.props.onMoveUnitCard(
                    this.props.index,
                    this.props.index + 1
                  )
                }
              />
            )}
            {this.props.index < this.props.arrayLength - 1 && (
              <CardButton
                style={{ gridArea: "2 / 7 / span 1 / span 1" }}
                name="angle-double-right"
                onClick={() => this.props.onMoveUnitCard(this.props.index, -1)}
              />
            )}
            <CardButton
              style={{ gridArea: "1 / 8 / span 1 / span 1", color: "darkRed" }}
              name="times"
              title="Remove"
              onClick={() => this.props.onRemoveUnitCard(this.props.index)}
            />
            <CardButton
              style={{ gridArea: "1 / 2 / span 1 / span 1", color: "black" }}
              name="clone"
              title="Clone"
              onClick={() => this.props.onCloneUnitCard(this.props.index)}
            />
          </div>
        </CardCell>

        {/* Name */}
        <CardCell
          registerCard={this.props.registerCard}
          unregisterCard={this.props.unregisterCard}
          row={rowIdx++}
          col={gridColumn}
          style={{
            alignSelf: "stretch",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p className="unit-card-title">{unit.name}</p>
        </CardCell>

        {/* Mount & Category */}
        <CardCell
          row={rowIdx++}
          col={gridColumn}
          style={{ alignSelf: "center", justifySelf: "stretch" }}
          className="flex-row"
        >
          {unit.battle_mounts && (
            <div
              style={{
                textAlign: "center",
                flexShrink: "0",
                flexGrow: "0",
                justifySelf: "start",
                width: "59px",
                height: "59px",
              }}
            >
              <MountPicker
                tww_version={unit.tww_version}
                battle_mounts={unit.battle_mounts}
                current={_.find(
                  unit.battle_mounts,
                  (m) => m.mounted_unit === unit.key
                )}
                onChange={(key) =>
                  this.props.onReplaceUnit(this.props.index, key)
                }
              />
            </div>
          )}
          <CategoryIcon
            name={unit.category}
            desc={unit.category_tooltip}
            special_category={unit.special_category}
            icon={unit.category_icon}
            tww_version={unit.tww_version}
            showName={true}
            style={{ justifyContent: "center", alignSelf: "center" }}
          />
        </CardCell>

        {/* Description */}
        {unit.short_description && (
          <CardCell row={rowIdx++} col={gridColumn} style={{}}>
            <CardBox>{unit.short_description}</CardBox>
          </CardCell>
        )}

        {/* Attributes */}
        <CardCell row={rowIdx++} col={gridColumn} style={{}}>
          <CardBox className="flex-column" style={{ alignItems: "start" }}>
            {unit.bullet_points &&
              unit.bullet_points.map((b, i) => (
                <BulletPoint
                  bullet={b}
                  tww_version={unit.tww_version}
                  icon_mappings={this.props.cuc.ui_tagged_images}
                  key={i}
                />
              ))}
          </CardBox>
        </CardCell>

        {/* Is Large, Unit Size, Upkeep?, XP */}
        <CardCell row={rowIdx++} col={gridColumn} style={{ alignSelf: "end" }}>
          <CardBox style={{ justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <GenericIcon
                style={{}}
                height={24}
                width={24}
                name={(unit.is_large && "Large Unit") || "Small Unit"}
                desc={
                  (unit.is_large &&
                    'This unit is affected by "Bonus_vs_Large"') ||
                  'This unit is affected by "Bonus_vs_Infantry"'
                }
                icon={
                  (unit.is_large &&
                    "ui/skins/default/icon_entity_large.webp") ||
                  "ui/skins/default/icon_entity_small.webp"
                }
              />
              <span>{unit.unit_size}</span>
            </div>
            <FatiguePicker
              fatigue={unit.fatigue}
              onChange={(fatigue) =>
                this.props.onFatigueChanged(this.props.index, fatigue)
              }
            />
            <RankPicker
              rank={unit.rank}
              ror={unit.ror}
              caste={unit.caste}
              onChange={(rank) =>
                this.props.onRankChanged(this.props.index, rank)
              }
            />
          </CardBox>
        </CardCell>

        {/* Hit Points, Armour, Leadership, Speed, Melee Attack, Melee Defence, Weapon Strength, Charge Bonus, Ammunition, Range, Missile Damage */}
        <CardCell
          row={rowIdx++}
          col={gridColumn}
          style={{ marginRight: `${unit.has_deltas ? "39px" : "0px"}` }}
        >
          <CardBox className="flex-column">
            <CardStat
              icon="ui/skins/default/icon_income.webp"
              name="SP Cost"
              unit={unit}
              field={"singleplayer_cost"}
            />
            <CardStat
              indentLevel={1}
              icon="ui/skins/default/icon_upkeep.webp"
              name="SP Upkeep"
              unit={unit}
              field={"singleplayer_upkeep"}
            />
            <CardStat
              icon="ui/skins/default/icon_treasury.webp"
              name="MP Cost"
              unit={unit}
              field={"multiplayer_cost"}
              tooltip={
                <p>
                  Does NOT include any item, ability or spell which is why
                  lords/heroes have such low values
                </p>
              }
            />

            <CardStat
              icon="ui/skins/default/icon_stat_health.webp"
              name="Health"
              unit={unit}
              field={"health"}
            />
            <CardStat
              indentLevel={1}
              icon="ui/skins/default/spacebar_unit_health_ammo.webp"
              name="Health per Entity"
              cloudinaryOptions={["h_22"]}
              unit={unit}
              field={"health_per_entity"}
            />
            <CardStat
              icon="ui/campaign ui/effect_bundles/barrier.webp"
              name="Barrier"
              unit={unit}
              field={"barrier_health"}
            />

            {/* Armour Group */}
            <CardStat
              icon="ui/skins/default/icon_stat_armour.webp"
              name="Armour"
              unit={unit}
              field={"armour"}
              icons={<ShieldIcon parry={unit.parry_chance} />}
              tooltip={
                <Fragment>
                  <p>
                    Reduces any non Armor-Piercing damage received. This
                    includes Magical and Flaming non Armor-Piercing damage.
                  </p>
                  <p>
                    Reduction amount is a random % between 0.5 * armour_value
                    and armour_value up to a maximum of 100%
                  </p>
                </Fragment>
              }
            />
            <CardStat
              indentLevel={1}
              icon="ui/skins/default/modifier_icon_shield.webp"
              name="Parry Chance"
              unit={unit}
              field={"parry_chance"}
            />
            <CardStat
              indentLevel={1}
              icon="ui/battle%20ui/ability_icons/resistance_ward_save.webp"
              cloudinaryOptions={["w_22", "h_22"]}
              name="Ward Save"
              unit={unit}
              field={"damage_mod_all"}
              tooltip={
                <Fragment>
                  <p>Reduces any damage received</p>
                  <p>
                    Resistances are applied independently of armor (dmg *
                    armor_reduction * resistance_reduction) and affect
                    Armor-Piercing Damage.
                  </p>
                  <p>
                    Against every attack, all aplicable resistances are added
                    (up to a maximum of 90, minimum of -100)
                  </p>
                </Fragment>
              }
            />
            <CardStat
              indentLevel={1}
              icon="ui/battle%20ui/ability_icons/resistance_physical.webp"
              cloudinaryOptions={["w_22", "h_22"]}
              name="Physical Resistance"
              unit={unit}
              field={"damage_mod_physical"}
              tooltip={
                <Fragment>
                  <p>Reduces any Non-Magical damage received</p>
                  <p>
                    Note that Flaming attacks are not considered "Magical" on
                    their own and are subject to Physical Resistance
                  </p>
                  <p>
                    Resistances are applied independently of armor (dmg *
                    armor_reduction * resistance_reduction)
                  </p>
                  <p>
                    Both Base Damage and Armor-Piercing Damage are affected by
                    resistances
                  </p>
                  <p>
                    Against every attack, all aplicable resistances are added
                    (up to a maximum of 90, minimum of -100)
                  </p>
                </Fragment>
              }
            />
            <CardStat
              indentLevel={1}
              icon="ui/battle%20ui/ability_icons/resistance_missile.webp"
              cloudinaryOptions={["w_22", "h_22"]}
              name="Missile Resistance"
              unit={unit}
              field={"damage_mod_missile"}
              tooltip={
                <Fragment>
                  <p>Reduces any Missile Damage received</p>
                  <p>
                    Resistances are applied independently of armor (dmg *
                    armor_reduction * resistance_reduction)
                  </p>
                  <p>
                    Both Base Damage and Armor-Piercing Damage are affected by
                    resistances
                  </p>
                  <p>
                    Against every attack, all aplicable resistances are added
                    (up to a maximum of 90, minimum of -100)
                  </p>
                </Fragment>
              }
            />
            <CardStat
              indentLevel={1}
              icon="ui/battle%20ui/ability_icons/resistance_magic.webp"
              cloudinaryOptions={["w_22", "h_22"]}
              name="Magic Resistance"
              unit={unit}
              field={"damage_mod_magic"}
              tooltip={
                <Fragment>
                  <p>Reduces any "Magical" Damage received</p>
                  <p>
                    Resistances are applied independently of armor (dmg *
                    armor_reduction * resistance_reduction)
                  </p>
                  <p>
                    Both Base Damage and Armor-Piercing Damage are affected by
                    resistances
                  </p>
                  <p>
                    Against every attack, all aplicable resistances are added
                    (up to a maximum of 90, minimum of -100)
                  </p>
                </Fragment>
              }
            />
            <CardStat
              indentLevel={1}
              icon="ui/battle%20ui/ability_icons/resistance_fire.webp"
              cloudinaryOptions={["w_22", "h_22"]}
              name="Fire Resistance"
              unit={unit}
              field={"damage_mod_flame"}
              tooltip={
                <Fragment>
                  <p>Reduces/Increases any "Flaming" Damage received</p>
                  <p>
                    Resistances are applied independently of armor (dmg *
                    armor_reduction * resistance_reduction)
                  </p>
                  <p>
                    Both Base Damage and Armor-Piercing Damage are affected by
                    resistances
                  </p>
                  <p>
                    Against every attack, all aplicable resistances are added
                    (up to a maximum of 90, minimum of -100)
                  </p>
                </Fragment>
              }
            />

            <CardStat
              icon="ui/skins/default/icon_stat_morale.webp"
              name="Leadership"
              unit={unit}
              field={"leadership"}
            />

            <CardStat
              icon="ui/skins/default/icon_stat_speed.webp"
              name="Speed"
              unit={unit}
              field={"speed"}
            />

            <CardStat
              icon="ui/skins/default/icon_stat_speed.webp"
              name="Charge Speed"
              unit={unit}
              field={"charge_speed"}
            />

            <CardStat
              icon="ui/skins/default/icon_stat_attack.webp"
              name="Melee Attack"
              unit={unit}
              field={"melee_attack"}
              icons={
                <Fragment>
                  <FlamingIcon
                    value={unit.primary_melee_weapon.ignition_amount}
                  />
                  <MagicIcon value={unit.primary_melee_weapon.is_magical} />
                  <PhaseIcon phase={unit.primary_melee_weapon.phase} />
                </Fragment>
              }
              tooltip={
                <Fragment>
                  <p>
                    % to Hit (TWW3) = 35 + 'Attacker's Melee Attack' -
                    'Defenders's Melee Defence'
                  </p>
                  <p>MIN 8%, MAX 90% (TWW3)</p>
                </Fragment>
              }
            />
            <CardStat
              indentLevel={1}
              icon="ui/skins/default/icon_status_melee_24px.webp"
              name="Attack Interval"
              unit={unit}
              field={"primary_melee_weapon.melee_attack_interval"}
              tooltip={
                <>
                  <p>
                    The attack interval is used to control the impact of
                    animations on attack frequency (e.g. a unit with a long
                    attack animation could be given a better attack frequency to
                    match similar units with a faster attack animation or
                    vice-versa)
                  </p>
                  <p>
                    As such, a difference in attack interval between two units
                    does NOT necessarily translate into a difference in "attack
                    speed".
                  </p>
                </>
              }
            />

            <CardStat
              indentLevel={1}
              icon="ui/skins/default/icon_status_alert_high_24px.webp"
              name="Is High Threat"
              unit={unit}
              field={"is_high_threat"}
              tooltip={
                <Fragment>
                  <p>
                    When a splash attack hits one or more "High Threat" entity,
                    ONE of the "High Threat" entity will be chosen to receive
                    ALL the damage.
                  </p>
                  <p>
                    This overrides the normal splash attack behavior of
                    splitting the damage between all hit entities.
                  </p>
                  <p>
                    This is NOT related to the "threat marker" located above
                    units in game.
                  </p>
                </Fragment>
              }
            />
            <CardStat
              indentLevel={1}
              fa="street-view"
              name="Splash Target Size"
              unit={unit}
              field={"primary_melee_weapon.splash_attack_target_size"}
              tooltip={
                <Fragment>
                  <p>
                    This unit can do Splash Attacks against entities up to this
                    size. Blank value means this unit cannot use Splash Attacks.
                  </p>
                </Fragment>
              }
            />
            <CardStat
              indentLevel={1}
              fa="users"
              name="Splash Max Attacks"
              unit={unit}
              field={"primary_melee_weapon.splash_attack_max_attacks"}
              tooltip={
                <Fragment>
                  <p>
                    The maximum number of entities this unit can hit with a
                    single Splash Attack.
                  </p>
                  <p>
                    Splash attack area is variable and determined by attack
                    animations (i.e. unrelated to this value).{" "}
                  </p>
                  <p>
                    Splash damage is always weapon damage spread across all
                    entities in target area up to max targets.
                  </p>
                  <p>
                    Each attack in a splash damage area has its own hit chance.
                  </p>
                </Fragment>
              }
            />

            <CardStat
              icon="ui/skins/default/icon_stat_defence.webp"
              name="Melee Defence"
              unit={unit}
              field={"melee_defence"}
              tooltip={
                <Fragment>
                  <p>
                    % to Hit (TWW3) = 35 + 'Attacker's Melee Attack' -
                    'Defenders's Melee Defence'
                  </p>
                  <p>MIN 8%, MAX 90% (TWW3)</p>
                </Fragment>
              }
            />

            {/* Weapon Damage Group */}
            <CardStat
              icon="ui/skins/default/icon_stat_damage.webp"
              name="Weapon Strength"
              unit={unit}
              field={"primary_melee_weapon.damage"}
              icons={
                <Fragment>
                  {unit.primary_melee_weapon.bonus_v_large > 0 && (
                    <GenericIcon
                      height={16}
                      width={16}
                      style={{ alignItems: "center" }}
                      name="Bonus vs. Large"
                      icon="ui/skins/default/modifier_icon_bonus_vs_large.webp"
                    />
                  )}
                  {unit.primary_melee_weapon.bonus_v_infantry > 0 && (
                    <GenericIcon
                      height={16}
                      width={16}
                      style={{ alignItems: "center" }}
                      name="Bonus vs. Infantry"
                      icon="ui/skins/default/modifier_icon_bonus_vs_infantry.webp"
                    />
                  )}
                </Fragment>
              }
            />
            <CardStat
              indentLevel={1}
              icon="ui/skins/default/icon_stat_damage.webp"
              name="Base Damage"
              unit={unit}
              field={"primary_melee_weapon.base_damage"}
            />
            <CardStat
              indentLevel={1}
              icon="ui/skins/default/modifier_icon_armour_piercing.webp"
              name="AP Damage"
              unit={unit}
              field={"primary_melee_weapon.ap_damage"}
            />
            <CardStat
              indentLevel={1}
              icon="ui/skins/default/modifier_icon_bonus_vs_large.webp"
              name="Bonus vs. Large"
              unit={unit}
              field={"primary_melee_weapon.bonus_v_large"}
              tooltip={<BonusVsLargeTooltip unit={unit} />}
            />
            <CardStat
              indentLevel={1}
              icon="ui/skins/default/modifier_icon_bonus_vs_infantry.webp"
              name="Bonus vs. Infantry"
              unit={unit}
              field={"primary_melee_weapon.bonus_v_infantry"}
              tooltip={<BonusVsInfantryTooltip unit={unit} />}
            />

            <CardStat
              icon="ui/skins/default/icon_stat_charge_bonus.webp"
              name="Charge Bonus"
              unit={unit}
              field={"charge_bonus"}
              tooltip={<ChargeBonusTooltip unit={unit} />}
            />

            {/* Missile Weapon */}
            <MissileWeaponCardContent
              name="Primary"
              unit={unit}
              field="primary_missile_weapon"
            />
            {globalSettings().show_secondary_missile_weapon === "true" ? (
              <MissileWeaponCardContent
                name="Secondary"
                unit={unit}
                field="secondary_missile_weapon"
                startCollapsed
              />
            ) : null}

            {/* Mass */}
            <CardStat
              icon="ui/campaign%20ui/effect_bundles/resource_marble.webp"
              name="Mass"
              unit={unit}
              field={"mass"}
              tooltip={
                <p>
                  The value of this field is my best guess at how mass is
                  computed. As a result, it could be wrong and/or innacurate in
                  many cases.
                </p>
              }
            />

            {/* Ground Effect */}
            <CardStat
              fa="tree"
              name="Ground Stat Effects"
              unit={unit}
              field={"ground_stat_effect_group"}
              value={unit.ground_stat_effect_group?.group_name}
              tooltip={
                unit.ground_stat_effect_group && (
                  <GroundStatEffectGroupRenderer
                    value={unit.ground_stat_effect_group}
                    contentOnly
                  />
                )
              }
            />

            {/* Fatigue */}
            <CardStat
              icon="ui/skins/default/icon_status_fatigue_24px.webp"
              name="Fatigue Modifier"
              unit={unit}
              field={"fatigue_modifier"}
              tooltip={
                <p>
                  Adjust the amount of "fatigue" a unit receives each tick. See
                  the _kv_fatigue page to see all possible fatigue modifiers.
                </p>
              }
            />
          </CardBox>
        </CardCell>

        {activeAbilities?.length > 0 ? (
          <CardCell row={rowIdx++} col={gridColumn}>
            <p className="unit-card-header">Abilities</p>
            <CardBox style={{ flexWrap: "wrap" }}>
              {activeAbilities.map((a) => (
                <AbilityIcon
                  key={a.key}
                  ability={a}
                  tww_version={unit.tww_version}
                  width={32}
                  height={32}
                  iconStyle={{ position: "relative", marginTop: "0px" }}
                />
              ))}
            </CardBox>
          </CardCell>
        ) : null}

        {unit.spells?.length > 0 ? (
          <CardCell row={rowIdx++} col={gridColumn}>
            <p className="unit-card-header">Spells</p>
            <CardBox style={{ flexWrap: "wrap" }}>
              {unit.spells.map((a) => (
                <AbilityIcon
                  key={a.key}
                  ability={a}
                  tww_version={unit.tww_version}
                  width={32}
                  height={32}
                  iconStyle={{ position: "relative", marginTop: "0px" }}
                />
              ))}
            </CardBox>
          </CardCell>
        ) : null}

        {passiveAbilities?.length > 0 ? (
          <CardCell row={rowIdx++} col={gridColumn}>
            <p className="unit-card-header">Passive Abilities</p>
            <CardBox style={{ flexWrap: "wrap" }}>
              {passiveAbilities.map((a) => (
                <AbilityIcon
                  key={a.key}
                  ability={a}
                  tww_version={unit.tww_version}
                  width={32}
                  height={32}
                  iconStyle={{ position: "relative", marginTop: "0px" }}
                />
              ))}
            </CardBox>
          </CardCell>
        ) : null}

        {unit.attributes?.length > 0 ? (
          <CardCell row={rowIdx++} col={gridColumn}>
            <p className="unit-card-header">Unit Attributes</p>
            <CardBox style={{ flexWrap: "wrap" }}>
              {unit.attributes.map((a) => (
                <AttributeIcon attribute={a} key={a.key} />
              ))}
            </CardBox>
          </CardCell>
        ) : null}

        {unit.can_siege ? (
          <CardCell row={rowIdx++} col={gridColumn}>
            <p className="unit-card-header">Additional Stats</p>
            <CardBox style={{ flexWrap: "wrap" }}>
              {
                <GenericIcon
                  style={{}}
                  height={32}
                  width={32}
                  name="Siege Attacker"
                  desc="This unit can attack city gates, allowing you to instantly launch a siege battle without having to wait for towers or batterig rams to be built."
                  icon="ui/battle ui/ability_icons/can_siege.webp"
                />
              }
            </CardBox>
          </CardCell>
        ) : null}
      </Fragment>
    );
  }
}

const UnitCard = ({ right, left, ...props }) => {
  const {
    loading: loadingRightCuc,
    error: errorRightCuc,
    data: dataRightCuc,
  } = useQuery(commonUnitConstants, {
    variables: { tww_version: right.v },
  });

  const {
    loading: loadingRight,
    error: errorRight,
    data: dataRight,
  } = useQuery(UnitQuery, {
    variables: { tww_version: right.v, key: right.k },
  });

  const {
    loading: loadingLeftCuc,
    error: errorLeftCuc,
    data: dataLeftCuc,
  } = useQuery(commonUnitConstants, {
    skip: !left,
    variables: left && { tww_version: left.v },
  });

  const {
    loading: loadingLeft,
    error: errorLeft,
    data: dataLeft,
  } = useQuery(UnitQuery, {
    skip: !left,
    variables: left && { tww_version: left.v, key: left.k },
  });

  if (!left) {
    if (loadingRightCuc || loadingRight) {
      return <p>Loading Unit Card {right.k}</p>;
    } else {
      let rightUnit = new Unit(
        dataRight.tww.unit,
        dataRight.tww.tww_version,
        dataRightCuc.tww,
        dataRight.tww.game_version,
        right.r,
        right.f
      );
      return (
        <UnitCardContent unit={rightUnit} cuc={dataRightCuc.tww} {...props} />
      );
    }
  } else {
    if (loadingRightCuc || loadingRight || loadingLeftCuc || loadingLeft) {
      return <p>Loading Unit Card {right.k}</p>;
    } else {
      let leftUnit = new Unit(
        dataLeft.tww.unit,
        dataLeft.tww.tww_version,
        dataLeftCuc.tww,
        dataLeft.tww.game_version,
        left.r,
        left.f
      );
      let rightUnit = new Unit(
        dataRight.tww.unit,
        dataRight.tww.tww_version,
        dataRightCuc.tww,
        dataRight.tww.game_version,
        right.r,
        right.f
      );
      rightUnit = deltaObject(leftUnit, rightUnit);

      return (
        <UnitCardContent unit={rightUnit} cuc={dataRightCuc.tww} {...props} />
      );
    }
  }
};

export default UnitCard;
