import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { useGameVersions } from "../context/game-versions-context";
import { useDebounce } from "@react-hook/debounce";

const GridControls = ({
  location,
  no_compare,
  history,
  WrappedComponent,
  gridClass,
  ...props
}) => {
  const parsed = queryString.parse(location.search);
  const { versions } = useGameVersions();

  const [selectedVersionLeft, setSelectedVersionLeft] = useState(parsed.left);
  const [selectedVersionRight, setSelectedVersionRight] = useState(
    parsed.right || versions[0].id
  );
  const [query, setQuery] = useDebounce(parsed.q, 400);

  useEffect(() => {
    if (
      parsed.q !== query ||
      parsed.left !== selectedVersionLeft ||
      parsed.right !== selectedVersionRight
    ) {
      parsed.q = query;
      parsed.left = selectedVersionLeft;
      parsed.right = selectedVersionRight;
      history.push({
        pathname: location.pathname,
        search: queryString.stringify(parsed),
      });
    }
  }, [
    query,
    selectedVersionLeft,
    selectedVersionRight,
    history,
    location.pathname,
    parsed,
  ]);

  const onQuickFilterText = (event) => {
    setQuery(event.target.value);
  };

  const onVersionChanged = (event, side) => {
    if (side === "left") setSelectedVersionLeft(event.target.value);
    else if (side === "right") setSelectedVersionRight(event.target.value);
  };

  return (
    <div id={gridClass ?? "units-grid"}>
      <div id={"top-filter"}>
        {no_compare ? null : (
          <select
            title="Select a version to compare"
            className="version-select"
            onChange={(event) => onVersionChanged(event, "left")}
            value={selectedVersionLeft}
          >
            {[
              <option key="0" value="">
                - - -
              </option>,
              ...versions.map((version) => (
                <option key={version.id} value={version.id}>
                  {version.game} - {version.name}
                </option>
              )),
            ]}
          </select>
        )}
        <select
          className="version-select"
          onChange={(event) => onVersionChanged(event, "right")}
          value={selectedVersionRight}
        >
          {versions.map((version) => (
            <option key={version.id} value={version.id}>
              {version.game} - {version.name}
            </option>
          ))}
        </select>
        <input
          id={"top-filter-input"}
          type="text"
          onChange={onQuickFilterText}
          placeholder="Type your Filter Query here..."
          autoFocus
          defaultValue={parsed.q}
        />
      </div>
      {WrappedComponent && (
        <WrappedComponent
          {...props}
          location={location}
          history={history}
          quickFilterText={query}
          tww_version_left={selectedVersionLeft}
          tww_version_right={selectedVersionRight}
          tww_version={selectedVersionRight} // Necessary for images and such
        />
      )}
    </div>
  );
};

export default GridControls;
